export enum RequestType {
	
	/// Монтаж
	install = 1,

	/// Сервис
	service = 2,

	/// Демонтаж (новый)
	dismantle = 3,

	/// Лаборатория
	laboratory = 4,

	/// Консультация
	consultation = 5,

	/// Бесплатный монтаж
	freeInstallation = 6,

	/// Акция монтаж
	saleInstallation = 7,

	/// Рекламация
	reclamation = 8,


	// OBSOLETE

	// Замена
	changeConfig = 10003,
	// Демонтаж
	uninstall = 10004,
	// Пополнение склада
	warehouseRefill = 10005,
	// Экспертиза
	expertise = 10006,
	// Перемещение между складами
    warehouseTransfer = 10007,
    // Выдача со склада
	issueFromWarehouse = 10008,
	// Сервис + Замена
	serviceOrReplace = 10009,
	// Оприходование
	warehousePosting = 100010,
	// Экспресс-экспертиза
	expressExpertise = 100011,
	// Демонтаж старый
	uninstallOld = 100012,
	// Групповая заявка
	groupRequest = 100013,
	// Монтаж ККТ (cash register equipment)
	installCRE = 100015,
	// Замена ККТ (cash register equipment)
	changeConfigCRE = 100016,
	// Прием на склад
	receiveToWarehouse = 100017,
	// Выдача/Прием со склада
	issueAndReceiveToWarehouse = 100018,
	// Согласование перемещения
	confirmRepair = 100019,
	// Коллизии данных оборудования
	collision = 100020,
	// Перемещение на внешний склад
	foreighWarehouseTransfer = 100021,
	// Регистрация
	registration = 100022,
	//Перемещение внутри склада
	internalWarehouseTransfer = 100023,

	/**Монтаж (удаленно) */
	remoteInstall = 100024, //Этот id указан в конфиге appsettings.json в Agreements > RequestTypes с AgreementId = 7, если он меняется тут, надо его поменять и там

	/**Монтаж с забором */
	installWithDismantle = 100025
}
