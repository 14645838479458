import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { PerformerUserContragent } from "../../../../../shared/models/contragent/performer-user-contragent";
import { LookupService } from "../../../../../shared/services/lookup.service";
import { HierarchyLookupModel } from "../../../../../shared/models/core/HierarchyLookupModel";
import { forkJoin } from "rxjs";
import { GridComponent } from "@progress/kendo-angular-grid";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { UsersService } from "../../../../../admin/users/users.service";
import { KeyValueObject } from "../../../../../shared/models/core/KeyValueObject";
import { PerformerUserContragentsService } from "../../../../../shared/services/performer-user-contragents.service";

interface PerformerUsersGroups {
    performerUserGroupId: number;
    performerUsers: KeyValueObject[];
}

@Component({
    selector: 'contragent-performer-users',
    templateUrl: './contragent-performer-users.component.html',
    styleUrls: [
        './contragent-performer-users.component.scss',
        '../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class ContragentPerformerUsersComponent implements OnInit {

    @ViewChild("performerUsersGrid") performerUsersGrid: GridComponent;

    dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'startsWith' };

    @Input() contragentId: number;

    performerUserContragents: PerformerUserContragent[] = [];
    userGroups: HierarchyLookupModel[] = [];
    performerUsersGroups: PerformerUsersGroups[] = [];
    performerUsersByGroup: KeyValueObject[] = [];

    loading: boolean = false;
    dataSaving: boolean = false;

    constructor(
        private performerUserContragentsService: PerformerUserContragentsService,
        private lookupService: LookupService,
        private usersService: UsersService
    ) { }

    ngOnInit(): void {
        this.loading = true;
        const performerUsers$ = this.performerUserContragentsService.getPerformerUsers(this.contragentId);
        const userGroups$ = this.lookupService.getHierarchyData("user-groups", null);

        forkJoin([performerUsers$, userGroups$]).subscribe(([performerUsers, userGroups]) => {
            this.userGroups = userGroups;
            this.performerUserContragents = performerUsers;
            this.performerUserContragents.forEach(x => x.oldPerformerUserGroupId = x.performerUserGroupId);

            performerUsers.map(x => this.usersService.getUsersByGroup(x.performerUserGroupId).subscribe((data) => {
                this.performerUsersGroups.push({
                    performerUserGroupId: x.performerUserGroupId,
                    performerUsers: data
                });
            }));
        
            this.loading = false;
        });
    }

    performerUserGroupChange(groupId: number, dataItem: PerformerUserContragent): void {
		if (groupId) {
            dataItem.performerUserId = null;
            if (!this.performerUsersGroups.some(x => x.performerUserGroupId == groupId)) {
                this.usersService.getUsersByGroup(groupId).subscribe(data => {
                    this.performerUsersGroups.push({
                        performerUserGroupId: groupId,
                        performerUsers: data
                    });

                    this.performerUsersByGroup = data;
                });
            }
            else {
                this.performerUsersByGroup = this.performerUsersGroups.find(x => 
                    x.performerUserGroupId == groupId).performerUsers;            
            }
		}		
	}

    createNewPerformerUser(): PerformerUserContragent {
        let performerUser = new PerformerUserContragent;
        performerUser.contragentId = this.contragentId;
        performerUser.isNew = true;
        return performerUser;
    }

    addNewPerformerUser() {
		this.performerUsersGrid.addRow(this.createNewPerformerUser());
	}

    savePerformerUser({ dataItem }: any) {
        this.dataSaving = true;
        if (dataItem.isNew) {
            this.performerUserContragentsService.create(dataItem).subscribe(() => {
                this.loadPerformerUsers();
                this.dataSaving = false;
            });
        }
        else {
            this.performerUserContragentsService.update(dataItem, null).subscribe(() => {
                this.loadPerformerUsers();
                this.dataSaving = false;
            });        
        }
    }

    removePerformerUser({ dataItem }: any) {
        this.dataSaving = true;
        this.performerUserContragentsService.delete(dataItem).subscribe(() => {
            this.loadPerformerUsers();
            this.dataSaving = false;
        });
    }

    public editPerformerUser({ sender, rowIndex, dataItem }) {
        this.performerUsersByGroup = this.performerUsersGroups.find(x => 
            x.performerUserGroupId == dataItem.performerUserGroupId).performerUsers;   
        
		sender.editRow(rowIndex);
	}

    loadPerformerUsers() {
        this.loading = true;
        this.performerUserContragentsService.getPerformerUsers(this.contragentId).subscribe((data) => {
            this.performerUserContragents = data;
            this.performerUserContragents.forEach(x => x.oldPerformerUserGroupId = x.performerUserGroupId);
            this.loading = false;
        })
    }

    public rowClass() {
		return {
			'cursor-pointer': true
		};
	}
}