import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { AttachmentType } from "../../../enums";
import { KeyValueObject } from "../../../models/core/KeyValueObject";
import { CarriersService } from "../../../services/carriers.service";
import { LookupService } from "../../../services/lookup.service";
import { RequestsService } from "../../../services/requests.service";
import { CarrierCompanyTrackModel } from "./carrier-company-track.model";
import { UploadAttachmentsModel, UploadFile } from "../../../models/attachment";
import { AttachmentsService } from "../../../services/attachments.service";
import { HttpResponse } from "@angular/common/http";


@Component({
	selector: 'set-carrier',
	templateUrl: './set-carrier.html',
	styleUrls: ['./set-carrier.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})

export class SetCarrier implements OnInit {
	
	@Input() activityId: number
	@Input() requestId: number;

	@Output() onContinueEvent: EventEmitter<any> = new EventEmitter();

	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	loading: boolean;
	dataSaving: boolean;

	carriers: KeyValueObject[] = [];

	carrierId: number;
	mustBeTrackNumber: boolean;
	companyName: string;
	trackNumber: string;
	isActLoaded: boolean;

	files: UploadFile[] = [];

	constructor(
		public dialog: DialogRef,
		private lookupService: LookupService,
		private carriersService: CarriersService,
		private requestsService: RequestsService,
		private attachmentsService: AttachmentsService
	) { }

	ngOnInit(): void {
		this.lookupService.getData("carriers", null).subscribe(data => this.carriers = data);
	}

	carrierValueChange(): void {
		if (this.carrierId && this.carrierId != 0) {
			this.carriersService.findById(this.carrierId).subscribe(carrier => {
				this.mustBeTrackNumber = carrier.entity.mustBeTrackNumber
				this.companyName = carrier.entity.name;
			});
		}
		else {
			this.mustBeTrackNumber = false;
			this.companyName = null;
		}
	}

	apply(): void {
		this.dataSaving = true;
		const carrierCompanyTrackModel = {
			requestId: this.requestId,
			carrierId: this.carrierId,
			companyName: this.companyName,
			trackNumber: this.trackNumber
		} as CarrierCompanyTrackModel;
		const uploadModel = new UploadAttachmentsModel({
			requestId: this.requestId,
			activityId: this.activityId,
			attachments: this.files,
			attachmentTypeId: AttachmentType.productInvoice
		});

		this.attachmentsService.upload(uploadModel).subscribe((x) => {
			if (x instanceof HttpResponse && x.status == 200) {
				this.requestsService.updateCarrierCompanyTrack(carrierCompanyTrackModel).subscribe(() => {
					this.onContinueEvent.emit();
					this.dataSaving = false;
					this.dialog.close();
				});
			}
		});
	}

	cancel(): void {
		if (this.dialog)
			this.dialog.close();
	}

	onUploadFilesAdded(files: UploadFile[] = []) {
		this.files = files;
		this.isActLoaded = files.length > 0;
	}

	get cardHeight(): number {
		return window.innerHeight - 250;
	}
}
