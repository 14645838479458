import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CustomerObjectRelatedRequestDto } from "../../../../shared/models/customerObject/customer-object-related-request-dto";
import { CustomerObjectsService } from "../../../../categories/customer-objects/customer-objects.service";

@Component({
    selector: 'new-request-related-requests',
    templateUrl: './new-request-related-requests.component.html',
    styleUrls: ['./new-request-related-requests.component.scss',
        '../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
        '../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
    ]
})
export class NewRequestRelatedRequestsComponent implements OnInit {
    
    @Input() customerObjectId: number;

    @Output() changeRelatedRequestId: EventEmitter<number> = new EventEmitter<number>();

    relatedRequests: CustomerObjectRelatedRequestDto[] = [];

    loading: boolean = false;

    constructor (
        private customerObjectsService: CustomerObjectsService
    ) {}

    ngOnInit(): void {
        this.loadData(this.customerObjectId);
    }

    loadData(customerObjectId: number) {
        if (customerObjectId) {
            this.loading = true;
            this.customerObjectsService.getCustomerObjectRelatedRequests(customerObjectId, null).subscribe(resp => {
                this.relatedRequests = resp;
                this.loading = false;
            });	
        }
        else {
            this.relatedRequests = [];
        }
    }

    changeRelatedRequest(event: any, dataItem: CustomerObjectRelatedRequestDto) {
        this.relatedRequests.forEach(x => {
            if (x.requestId != dataItem.requestId) {
                x.selected = false;
            }
        });

        let selectedRequestId = event.target.checked
            ? dataItem.requestId
            : null;

        this.changeRelatedRequestId.emit(selectedRequestId);
    }
    
    public rowClass() {
        return {
            'cursor-pointer': true
        };
    }

    cellClick({ dataItem, columnIndex }: any) {
        if (columnIndex <= 3) {
            window.open(`/requests/${dataItem.requestId}`, '_blank');
        }
	}
}