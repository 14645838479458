import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { RequestsService } from "../../../services/requests.service";
import { KeyValueObject } from "../../../models/core/KeyValueObject";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { DialogRef } from "@progress/kendo-angular-dialog";

@Component({
    selector: 'set-problem-resolution',
    templateUrl: './set-problem-resolution.component.html',
    styleUrls: ['./set-problem-resolution.component.scss']
})
export class SetProblemResolutionComponent implements OnInit {

    public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

    @Input() requestId: number;
    @Input() claimId: number;
    @Input() resolution: string;

    @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
    @Output() onContinue: EventEmitter<any> = new EventEmitter<any>();

    problems: KeyValueObject[] = [];

    loading: boolean = false;

    constructor(
        private dialog: DialogRef,
        private requestsService: RequestsService
    ) { }

    ngOnInit(): void {
        this.loading = true;
        this.requestsService.getRequestProblems(this.requestId).subscribe(resp => {
            this.problems = resp.data;
            this.loading = false;
        });
    }

    cancel() {
        this.onCancel.emit();
        this.dialog.close();
    }

    continue() {
        this.loading = true;
        this.requestsService.updateRequestProblemResolution(this.requestId, this.claimId, this.resolution).subscribe(() => {
            this.onContinue.emit();
            this.dialog.close();
        });
    }

    get canSave(): boolean {
        return this.claimId && this.resolution != null && this.resolution.trim() != '';
    }
}