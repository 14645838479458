import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { RequestsService } from "../../../services/requests.service";
import { NewRequestService } from "../../../models/request/new-request/new-request-service";
import { RequestServicesService } from "../../../services/request-services.service";
import { CategoryServicesService } from "../../../services/category-services.service";
import { KeyValueObject } from "../../../models/core/KeyValueObject";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { NewRequestDevice } from "../../../models/request/new-request/new-request-device";
import { RequestDevicesService } from "../../../services/request-devices.service";
import { ReserveResult } from "../../../enums/reserve-result.enum";
import { UploadAttachmentsModel, UploadFile } from "../../../models/attachment";
import { AttachmentsService } from "../../../services/attachments.service";
import { RequestType } from "../../../enums";
import { LookupService } from "../../../services/lookup.service";
import { DeviceModelsService } from "../../../services/device-models.service";
import { forkJoin } from "rxjs";
import { DeviceModelDto } from "../../../models/device/device-model-dto";

@Component({
	selector: 'finish-activity-with-devices-services',
	templateUrl: './finish-activity-with-devices-services.html',
	styleUrls: ['./finish-activity-with-devices-services.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class FinishActivityWithDevicesServices implements OnInit {
    
    @Input() requestId: number;
    @Input() requestTypeId: number;
    @Input() claimId: number;
    @Input() resolution: string;
    @Input() activityId: number;

    @Output() onContinueEvent: EventEmitter<any> = new EventEmitter();
    @Output() onCancelEvent: EventEmitter<any> = new EventEmitter();

    deviceTypes: KeyValueObject[] = [];
    deviceModels: DeviceModelDto[] = [];
    dropDownDeviceModels: DeviceModelDto[] = [];
    deviceSuppliers: DeviceModelDto[] = [];
    dropDownDeviceSuppliers: DeviceModelDto[] = [];
    marketPlaces: KeyValueObject[] = [];

    attachments: UploadFile[] = [];
    requestServices: NewRequestService[] = [];
    requestDevices: NewRequestDevice[] = [];
    reclamationRequestDevices: NewRequestDevice[] = [];
    problems: KeyValueObject[] = [];

    filesUploaded: boolean = false;
    dataSaving: boolean = false;
    loadingServices: boolean = false;
    loadingDevices: boolean = false;

    dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

    constructor(
        private requestsService: RequestsService,
        private requestServicesService: RequestServicesService,
        private requestDevicesService: RequestDevicesService,
        private attachmentsService: AttachmentsService,
        private lookupService: LookupService,
        private deviceModelsService: DeviceModelsService
    ) { }

    ngOnInit(): void {
        this.loadRequestServices();
        this.loadRequestDevices();
        this.loadRequestProblems();
        this.initLookups();
    }

    loadRequestServices() {
        this.loadingServices = true;
        this.requestsService.getRequestServices(this.requestId).subscribe(resp => {
            this.requestServices = resp;
            this.loadingServices = false;
        });
    }

    loadRequestDevices() {
        this.loadingDevices = true;

        this.requestsService.getRequestDevices(this.requestId)
            .subscribe(requestDevices => {
                if (this.isReclamation) {
                    this.requestDevices = requestDevices.filter(x => 
                        x.reserveResultId != ReserveResult.installedOnTsp || !x.inCustomerObject);
                    this.reclamationRequestDevices = requestDevices.filter(x => 
                        x.reserveResultId == ReserveResult.installedOnTsp && x.inCustomerObject);
                }
                else {
                    this.requestDevices = requestDevices;            
                }
    
                this.requestDevices.forEach(x => {
                    x.toDelivery = x.reserveResultId == ReserveResult.installedOnTsp;
                });

                this.loadingDevices = false;
            });
    }

    loadRequestProblems() {
        this.requestsService.getRequestProblems(this.requestId).subscribe(resp => {
            this.problems = resp.data;
        });
    }

    initLookups() {
        const marketPlaces$ = this.lookupService.getData('device-market-places', null);
        const deviceTypes$ = this.lookupService.getData('device-types', null);
        const deviceModels$ = this.deviceModelsService.getAll();

        forkJoin(deviceTypes$, deviceModels$, marketPlaces$)
        .subscribe(([deviceTypes, deviceModels, marketPlaces]) => {
            this.deviceTypes = deviceTypes;
            this.deviceModels = deviceModels;
            this.dropDownDeviceModels = [];
            this.deviceSuppliers = this.deviceModels.filter(x => x.parentId == null);
            this.dropDownDeviceSuppliers = [];
            this.marketPlaces = marketPlaces;
        });
    }

    saveChanges() {
        this.dataSaving = true;
        this.filesUploaded = false;

        const uploadModel = new UploadAttachmentsModel({ 
            requestId: this.requestId,
            activityId: this.activityId,
            attachments: this.attachments 
        });

		this.attachmentsService.upload(uploadModel).subscribe(() => {
            if (!this.filesUploaded) {
                this.filesUploaded = true;
                if (this.isReclamation) {
                    this.requestsService.updateRequestProblemResolution(this.requestId, this.claimId, this.resolution).subscribe(() => {
                        this.onContinueEvent.emit();
                        this.dataSaving = false;
                    });
                }
                else {
                    this.onContinueEvent.emit();
                    this.dataSaving = false;                
                }
            }
        });
    }

    cancel() {
        this.onCancelEvent.emit();
    }

    // методы для оборудования

    saveRequestDeviceHandler({ dataItem }: any) {
        this.dataSaving = true;
        let requestDevice = <NewRequestDevice>dataItem;
        requestDevice.reserveResultId = requestDevice.toDelivery 
            ? <number>ReserveResult.installedOnTsp
            : <number>ReserveResult.pendingIssueToEngineer;

        this.requestDevicesService.update(requestDevice, null).subscribe(() => {
            this.dataSaving = false;
            this.loadRequestProblems();
            this.loadRequestDevices();
        });
    }

    turnRequestDevice(event: any, dataItem: NewRequestDevice) {
        dataItem.toDelivery = event.target.checked;
        this.saveRequestDeviceHandler({dataItem});
    }

    public editRequestDeviceHandler({ sender, rowIndex, dataItem }) {
        this.handleDeviceSuppliersDropdownOpen(dataItem.deviceTypeId);
        this.handleDeviceModelsDropdownOpen(dataItem.deviceSupplierId);

		sender.editRow(rowIndex);
	}

    createNewRequestDevice(): NewRequestDevice {
        let device = new NewRequestDevice();
        device.manuallyAdded = true;
        device.requestId = this.requestId;
        return device;
    }

    
    public deviceTypeChange(deviceTypeId: number, requestDevice: NewRequestDevice = null) {
        if (requestDevice) {
            requestDevice.deviceModelId = null;
            requestDevice.deviceSupplierId = null;
        }
    }

    public deviceSupplierChange(supplierId: number, requestDevice: NewRequestDevice = null) {
        if (requestDevice) {
            requestDevice.deviceModelId = null;
        }
    }

    public handleDeviceSuppliersDropdownOpen(deviceTypeId: number) {
        this.dropDownDeviceSuppliers = this.deviceModels != null && deviceTypeId
            ? this.deviceSuppliers.filter(x => x.typeId == deviceTypeId)
            : [];
    }

    public handleDeviceModelsDropdownOpen(deviceSupplierId: number) {
		this.dropDownDeviceModels = this.deviceModels != null && deviceSupplierId
            ? this.deviceModels.filter(x => x.parentId === deviceSupplierId)
            : [];
	}

    canSaveRequestDevice(requestDevice: NewRequestDevice): boolean {
        return requestDevice.customerDevice == false ||
            (requestDevice.customerDevice == true &&
            requestDevice.deviceTypeId && 
            requestDevice.deviceSupplierId && 
            requestDevice.deviceModelId && 
            requestDevice.serialNumber && requestDevice.serialNumber.trim() != '');
    }

    // методы для услуг

    saveRequestServiceHandler({ dataItem }: any) {
        this.dataSaving = true;
        this.requestServicesService.update(dataItem, null).subscribe(() => {
            this.dataSaving = false;
            this.loadRequestServices();
        })
    }

    turnRequestService(event: any, dataItem: NewRequestService) {
        dataItem.isDone = event.target.checked;
        this.saveRequestServiceHandler({dataItem});
    }

    // Другие общие методы

    onAttachmentsAdded(attachments: UploadFile[]) {
		this.attachments = attachments;
	}

    getNameById(items: KeyValueObject[] = [], id: number): string {
        return items.some(x => x.id == id)
            ? items.find(x => x.id == id).name
            : '';
    }

    createRequestService(): NewRequestService {
        var requestService = new NewRequestService();
        requestService.requestId = this.requestId;
        requestService.isDefault = false;
        return requestService;    
    }


    public rowClass() {
		return {
			'cursor-pointer': true
		};
	}

    get canSave(): boolean {
        let commomCompleted = !this.loadingServices && !this.loadingDevices && !this.dataSaving && this.attachments.length > 0;
        let reclamationCompleted = this.isReclamation 
            ? this.claimId && this.resolution && this.resolution.trim() != ''
            : true;

        return commomCompleted && reclamationCompleted;
    }

    get isReclamation(): boolean {
        return this.requestTypeId == <number>RequestType.reclamation;
    }
}