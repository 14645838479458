import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { RequestBwt } from "../../../models/request/request-bwt";
import { KeyValueObject } from "../../../models/core/KeyValueObject";
import { LookupService } from "../../../services/lookup.service";
import { RequestTypeWithCategoriesModel } from "../../../models/request/request-type-with-categories-model";
import { RequestCategotyWithTypesModel } from "../../../models/request/request-categoty-with-types-model";
import { RequestCategoryService } from "../../../services/request-categoty.service";
import { RequestTypeService } from "../../../services/request-type.service";
import { HierarchyLookupModel } from "../../../models/core/HierarchyLookupModel";
import { NgForm } from "@angular/forms";
import { RequestsService } from "../../../services/requests.service";
import { forkJoin } from "rxjs";
import { CreateContragentComponent } from "../../contragents/create-contragent/create-contragent.component";
import { DialogService } from "@progress/kendo-angular-dialog";
import { ContragentsService } from "../../../../categories/contragents/contragents.service";
import { ContragentWithContactDto } from "../../../models/contragent/contragent-with-contact-dto";
import { UsersService } from "../../../../admin/users/users.service";
import { CustomerObjectsService } from "../../../../categories/customer-objects/customer-objects.service";
import { RequestType } from "../../../enums";

@Component({
	selector: 'request-page-main',
	templateUrl: './request-page-main.component.html',
	styleUrls: ['./request-page-main.component.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	],
	encapsulation: ViewEncapsulation.None,
})
export class RequestPageMainComponent implements OnInit {

	@ViewChild('entityForm') public entityForm: NgForm;

    @Input() request: RequestBwt;
	@Input() dataSaving: boolean;
	@Input() blockHeight: number;

	@Output() requestUpdateEvent: EventEmitter<RequestBwt> = new EventEmitter<RequestBwt>();

	requestStatuses: KeyValueObject[] = [];
    dependentRequestCategories: RequestCategotyWithTypesModel[] = [];
    requestTypes: RequestTypeWithCategoriesModel[] = [];
    requestCategories: RequestCategotyWithTypesModel[] = [];
	userGroups: HierarchyLookupModel[] = [];
	workflowTransitions: KeyValueObject[] = [];
	performersByGroup: KeyValueObject[] = [];
	customerObjects: KeyValueObject[] = [];
	problems: KeyValueObject[] = [];

	selectedContragent: ContragentWithContactDto = null;

	descriptionInputRows: number = 1;
	isEditing: boolean = false;

	constructor(
		private requestsService: RequestsService,
		private lookupService: LookupService,
		private requestTypeService: RequestTypeService,
		private requestCategoryService: RequestCategoryService,
		private contragentsService: ContragentsService,
		private dialogService: DialogService,
		private usersService: UsersService,
		private customerObjectsService: CustomerObjectsService
	) { }

	ngOnInit(): void {
		this.initData();

		this.prepareRequestData();
	}

	initData() {
		const requestTypes$ = this.requestTypeService.getRequestTypesWithCategories();
		const requestCategories$ = this.requestCategoryService.getRequestCategoriesWithTypes();
		const selectedContragent$ = this.contragentsService.getContragentWithContact(this.request.customerContragentId);
		const customerObjects$ = this.customerObjectsService.getCustomerObjectsByContragent(this.request.customerContragentId);
		const problems$ = this.requestsService.getRequestProblems(this.request.requestId);

		forkJoin([requestTypes$, requestCategories$, selectedContragent$, customerObjects$, problems$])
		.subscribe(result => {
			this.requestTypes = result[0];
			this.requestCategories = result[1];
			this.dependentRequestCategories = this.requestCategories.filter(x => 
				x.requestTypeIds.some(rt => rt == this.request.requestTypeId));
			this.selectedContragent = result[2];
			this.customerObjects = result[3];
			this.problems = result[4].data;
		});

		this.lookupService.getData("request-statuses", null).subscribe((response) => {
			this.requestStatuses = response;
		});
		this.lookupService.getHierarchyData("user-groups", null).subscribe((response) => {
			this.userGroups = response;
		});
		this.loadPerformerUsers(this.request.performerUserGroupId);
	}

	loadContragent() {
		this.contragentsService.getContragentWithContact(this.request.customerContragentId).subscribe(resp => {
			this.selectedContragent = resp;
		});
	}

	saveChanges() {
		this.dataSaving = true;
		this.requestsService.getById(this.request.requestId).subscribe(resp => {
			let entity = resp.entity;
			entity.requestTypeId = this.request.requestTypeId;
			entity.requestCategoryId = this.request.requestCategoryId;
			entity.performerUserGroupId = this.request.performerUserGroupId;
			entity.performerUserId = this.request.performerUserId;
			entity.createdDate = this.request.createdDate;
			entity.finishDate = this.request.finishDate;
			entity.description = this.request.description;
			entity.customerObjectId = this.request.customerObjectId;
			entity.claimId = this.request.claimId;
			entity.resolution = this.request.resolution;
			this.requestsService.update(entity, null, false, false).subscribe(() => {
				this.requestsService.getBwtById(this.request.requestId).subscribe(updatedRequest => {
					this.requestUpdateEvent.emit(updatedRequest.entity);
					this.request = updatedRequest.entity;
					this.prepareRequestData();
					this.dataSaving = false;
					this.isEditing = false;
				});
			}, () => { this.dataSaving = false; this.isEditing = false; });
		});
	}

	beforeChangeStatus() {
		this.dataSaving = true;
	}

	cancelChangeStatus() {
		this.dataSaving = false;
	}

	afterChangeStatus() {
		this.requestsService.getBwtById(this.request.requestId).subscribe(updatedRequest => {
			this.requestUpdateEvent.emit(updatedRequest.entity);
			this.request = updatedRequest.entity;
			this.prepareRequestData();
			this.dataSaving = false;
			this.isEditing = false;
		});
	}

	openContragentModal() {
		const dialogRef = this.dialogService.open({ content: CreateContragentComponent, width: '60%', height: '640px' });
		const createContragentForm = <CreateContragentComponent>dialogRef.content.instance;
		
		createContragentForm.isEditing = true;
		createContragentForm.contragentEntity = this.selectedContragent;
		createContragentForm.onCancelEvent.subscribe(() => dialogRef.close());
		createContragentForm.onContinueEvent.subscribe((contragent) => {
			this.selectedContragent = contragent;
			this.requestsService.getBwtById(this.request.requestId).subscribe(updatedRequest => {
				this.requestUpdateEvent.emit(updatedRequest.entity);
				this.request = updatedRequest.entity;
				this.prepareRequestData();
				dialogRef.close();
			});		
		});
	}

	performerUserGroupChange(groupId: number): void {
		if (groupId) {
			this.loadPerformerUsers(groupId);
		}		
	}

	loadPerformerUsers(groupId: number): void {
		if (groupId) {
			this.usersService.getUsersByGroup(groupId).subscribe(data => this.performersByGroup = data);
		}
	}

	editRequest() {
		this.isEditing = !this.isEditing;
	}

	getRequestStatusName(statusId: number): string {
		return this.requestStatuses.some(x => x.id == statusId)
			? this.requestStatuses.find(x => x.id == statusId).name
			: '';
	}

	requestTypeChange(requestTypeId: number) {
		this.dependentRequestCategories = requestTypeId != null && requestTypeId != 0
			? this.requestCategories.filter(x => x.requestTypeIds.some(rt => rt == requestTypeId))
			: this.requestCategories;
		this.request.requestCategoryId = this.dependentRequestCategories.length == 1
			? this.dependentRequestCategories[0].id
			: null;
    }

	prepareRequestData() {
		this.formatWorkflowTransitions();
		this.formatDates();
		this.requestDescriptionChange();
	}

	requestDescriptionChange() {
		if (this.request.description != null) {
			this.descriptionInputRows = this.request.description.split('\n').length;
		}
	}
	
	formatWorkflowTransitions() {
		this.workflowTransitions = this.request.workflowTransitions.map(x => {
			return new KeyValueObject(x.transitionId, x.transitionName);
		});
		this.workflowTransitions.unshift(new KeyValueObject(this.request.requestStatusId, this.request.requestStatusName));
	}

	formatDates() {
		this.request.createdDate = new Date(this.request.createdDate);
		if (this.request.finishDate != null) {
			this.request.finishDate = new Date(this.request.finishDate);
		}
	}

	get isReclamation(): boolean {
		return this.request.requestTypeId == <number>RequestType.reclamation;
	}

	get customerContragentName(): string {
		return this.selectedContragent
			? this.selectedContragent.isIndividual 
				? this.selectedContragent.contactFullName
				: this.selectedContragent.name
			: this.request
				? this.request.customerContragentName
				: '';
	}

	get requestStatusesByWorkflowTransitions(): KeyValueObject[] {
		let requestStatuses = [
			new KeyValueObject(this.request.requestStatusId, this.request.requestStatusName)
		];
		this.request.workflowTransitions.map(x => {
			requestStatuses.push(
				new KeyValueObject(x.requestStatusToId, this.getRequestStatusName(x.requestStatusToId)));
		});
		return requestStatuses;
	}

	get canSave(): boolean {
		return !this.entityForm.pristine && this.entityForm.valid;
	}
}