import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ComponentPageBase } from "../../shared/components/component-page-base/component-page-base";
import { RequestBwt } from "../../shared/models/request/request-bwt";
import { RequestsService } from "../../shared/services/requests.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "../../app.service";
import { NotificationService } from "../../core/services/notification.service";
import { NgbTabChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { EntityViewModel } from "../../shared/models/core/EntityViewModel";
import { TrackChangesComponent } from "../../core/interfaces/component-can-deactivate";
import { SecurityService } from "../../core/services/security.service";
import { Activity } from "../../shared/models/activity/activity";
import { ActivityStatusEnum } from "../../shared/enums";
import { ActivitiesService } from "../../shared/services/activities.service";
import { KeyValueObject } from "../../shared/models/core/KeyValueObject";
import { DataSourceRequestState } from "@progress/kendo-data-query";
import { LookupService } from "../../shared/services/lookup.service";
import { RequestPageMainComponent } from "../../shared/components/request-page/request-page-main/request-page-main.component";
import { CommentsBlockComponent } from "../../shared/components/comments/comments-block/comments-block.component";
import { NotificationType } from "../../core/services/notification-type";
import { environment } from "../../../environments/environment";
import { UsersService } from "../../admin/users/users.service";

@Component({
	selector: 'request',
	templateUrl: './request.page.html',
	styleUrls: [
		'./request.page.scss',
		'../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../vendor/libs/ngx-contextmenu/ngx-contextmenu.scss',
	]
})
export class RequestPage implements OnInit, OnDestroy, TrackChangesComponent {
  
	@ViewChild("requestPageMain") requestPageMainComponent: RequestPageMainComponent;
	@ViewChild("commentBlock") commentBlock: CommentsBlockComponent;

	private entityId: string;
	private entityIdInt: number;

	public activeIdString: string = "main";

	public entityViewModel: EntityViewModel<RequestBwt>;

	showActivitiesGrid: boolean = true;
	showActivityDetails: boolean = true;
	selectedActivities: number[] = [];
	activities: Activity[] = [];
	selectedActivity: Activity = null;
	serviceCenters: KeyValueObject[] = [];

	dataSaving: boolean = false;
	destroy: boolean = false;

	constructor(
		public requestsService: RequestsService,
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		private securityService: SecurityService,
		private activitiesService: ActivitiesService,
		private lookupService: LookupService,
		private usersService: UsersService
	) {
		this.appService.pageTitle = 'Заявка';	
	}

	get showMainTab(): boolean {
		return this.isSpecialRightClaimValid("Main") || this.securityService.isAdmin();
	}

	get showFilesTab(): boolean {
		return (this.isSpecialRightClaimValid("Files") || this.securityService.isAdmin())
			&& this.securityService.hasClaim('cat-requestfiles-read');
	}

	get showServicesTab(): boolean {
		return this.isSpecialRightClaimValid("Services") || this.securityService.isAdmin();
	}

	get showCustomerObjectTab(): boolean {
		return this.isSpecialRightClaimValid("CustomerObject") || this.securityService.isAdmin();
	}

	get showEquipmentTab(): boolean {
		return this.isSpecialRightClaimValid("Equipment") || this.securityService.isAdmin();
	}

	get showActivitiesTab(): boolean {
		return this.isSpecialRightClaimValid("Activities") || this.securityService.isAdmin();
	}

	get showNotificationsTab(): boolean {
		return this.isSpecialRightClaimValid("Notifications") || this.securityService.isAdmin();
	}

	get showChangesHistoryTab(): boolean {
		return this.isSpecialRightClaimValid("ChangesHistory") || this.securityService.isAdmin();
	}

	get isMobileDevice(): boolean {
		return false;;
	}

	get selectedActivityId(): number {
		if (this.selectedActivities.length === 0) {
			return null;
		}

		return this.selectedActivities[0];
	}

	public statusIsCanceled(item: any): boolean {
		return item && item.statusId == <number>ActivityStatusEnum.cancelled;
	}
	
	isSpecialRightClaimValid(claim: string): boolean {
		return this.securityService.isSpecialRightClaimValid(claim);
	}

	hasChanges(): boolean {
		return false;
	}

	ngOnDestroy(): void {
		this.destroy = true;
    }

    ngOnInit(): void {
		this.entityId = this.route.snapshot.paramMap.get('requestId');
		this.entityIdInt = Number(this.entityId);
		
		this.requestsService.getBwtById(this.entityId).subscribe((vm) => {
			this.entityViewModel = vm;
			this.checkPerformerUser();
		});

		const scState: DataSourceRequestState = {
			filter: {
				logic: 'and', filters: [
					{ field: 'IsActual', operator: 'eq', value: true },
				]
			}
		};

		this.lookupService.getData("service-centers", scState).subscribe(data => {
			this.requestsService.requestSc(+this.entityId).subscribe(scId => {
				if (!!scId && !data.some(s => s.id === scId)) {

					const scState2: DataSourceRequestState = {
						filter: {
							logic: 'and', filters: [
								{ field: 'ServiceCenterId', operator: 'eq', value: scId },
							]
						}
					};

					this.lookupService.getData("service-centers", scState2).subscribe(data2 => {
						let disElem = data2.find(f => f.id === scId);

						if (!!disElem) {
							disElem.disabled = true;
							this.serviceCenters = data;
							this.serviceCenters.push(disElem);
						}
					});
				} else {
					this.serviceCenters = data;
				}
			})

		});
    }

	checkPerformerUser() {
		if (!this.entityViewModel.entity.performerUserId) {
			setTimeout(() => 
				this.setCurrentPerformerUser(),
				environment.performerUserAutoSetTime
			);
		}
	}

	setCurrentPerformerUser() {
		if (!this.entityViewModel.entity.performerUserId && !this.destroy) {
			this.dataSaving = true;
			this.requestsService.changePerformerUser(this.entityIdInt, null, true).subscribe((resp) => {
				if (resp.data != null) {
					this.usersService.getUsersByGroup(this.entityViewModel.entity.performerUserGroupId).subscribe(users => {
						this.entityViewModel.entity.performerUserId = resp.data;
						this.entityViewModel.entity.performerUserName = users.find(x => x.id == resp.data).name;

						if (this.commentBlock) {
							this.commentBlock.loadComments(0, null);
						}

						this.dataSaving = false;
						this.notificationService.success({
							title: "Вы назначены ответсвенным по заявке",
							notificationType: NotificationType.Toast
						});
					});
				}
				this.dataSaving = false;
			});
		}
	}

	tabChanged(e: NgbTabChangeEvent) {
		this.activeIdString = e.nextId;
	}
	
	showActivityById(activityId: number) {

		if (this.isMobileDevice) {
			this.showActivitiesGrid = false;
			this.showActivityDetails = true;
		}

		this.selectedActivities = [];
		let activity = this.activities.find(x => x.activityId == activityId);
		if (!this.statusIsCanceled(activity)){
			this.selectedActivities.push(activityId);
		}
		this.onSelectedActivityChange();
	}

	goToActivity(activityId: number) {
		this.activeIdString = "activities-tab";
		this.showActivityById(activityId);
	}

	refreshRequest(request: RequestBwt) {
		this.entityViewModel.entity = request;
		if (this.requestPageMainComponent) {		
			this.requestPageMainComponent.request = request;
			this.requestPageMainComponent.loadContragent();
		}

		this.checkPerformerUser();
		this.commentBlock.loadComments(0, null);
	}

	public onSelectedActivityChange() {
		if (this.selectedActivityId) {
			this.activitiesService.getById(this.selectedActivityId).subscribe(x => {
				this.selectedActivity = x.entity;
	
				// if (this.activityComments)
				// 	this.activityComments.loadComments(0, this.getSelectedActivityPerformerUserId());
	
				// this.fitActivitiesColumns();
	
				// if (this.activeIdString == "activities-tab")
				// 	this.loadFines()
			});
		}
	}

	public getServiceCenter(): string {
		let thisSc = this.serviceCenters.find(x => x.id === this.entityViewModel.entity.serviceCenterId);
		if (thisSc) {
			return thisSc.name;
		}
		return '';
	}

	get requestMainHeight(): number {
		const biggestHeight = 1000;
		let innerHeight = window.innerHeight;
		let mainHeight = 655;

		let middleProcentHeight = this.getProcentValue(innerHeight, 80);

		if (middleProcentHeight > mainHeight && innerHeight < biggestHeight) {
			mainHeight = middleProcentHeight;
		}
		else if (innerHeight > biggestHeight) {
			mainHeight = this.getProcentValue(innerHeight, 70);
		}

		return mainHeight;
	}

	private getProcentValue(value: number, procent: number): number {
		return value / 100 * procent;
	}
}