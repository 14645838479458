import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { NewRequestDevice } from '../models/request/new-request/new-request-device';
import { Observable } from 'rxjs';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { environment } from '../../../environments/environment';
import { tap, filter, pluck } from 'rxjs/operators';
import { NotificationType } from '../../core/services/notification-type';
import { RequestDeviceWarehouseRefillDto } from '../models/request/request-device-warehouse-refills';
import { IRequestDeviceNomenclatureQuery1c } from '../models/request/request-device-nomenclature-query-1c.interface';
import { AvailableNomenclatureViewModel } from '../models/device/available-nomenclature-viewmodel';
import { SerialNumbersResult } from '../models/device/serial-numbers-1C/serial-numbers-result';
import { ChangeActivityStatusModel } from '../models/activity/change-activity-status.model';
import { ActionResult } from '../models/core/ActionResult';
import { SerialNumbersFormDevice } from '../models/device/serial-numbers-1C/serial-numbers-form-device';
import { ReturnedDevice } from '../models/device/returned-device';
import { ExpertiseActFormList } from '../models/expertise-act/expertise-act-form-list';
import { ExpertiseActFormItem } from '../models/expertise-act/expertise-act-form-item';
import { SerialNumbersDevice } from '../models/device/serial-numbers-1C/serial-numbers-device';
import { AvailableUnavailableSerialNumber } from '../models/device/serial-numbers-1C/available-serial-number';
import { ErrorCodes1C } from '../enums/error-codes-1c';
import { ArticleDto } from '../models/article/article.dto';
import { GetAvailableNomenclaturesDto } from '../models/device/get-available-nomenclatures-dto';

@Injectable()
export class RequestDevicesService extends DataService<NewRequestDevice, NewRequestDevice> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'request-devices';
	}

	public reserveRequestDevices(requestId: number, moveFromRequestDeviceIds: number[] = []): Observable<ActionDataResult<boolean>> {
		let url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/reserve`;

		return this.http.post<ActionDataResult<boolean>>(url, {
			requestId: requestId,
			moveFromRequestDeviceIds: moveFromRequestDeviceIds
		})
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка при запросе резерва',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
				else {

					// получили ответ от 1С - можем обновить список устройств
					if (resp.data) {
						this.notificationService.success({
							title: "Заказ резерва",
							message: "Успешно отправлен. Результаты резервирования получены.",
							notificationType: NotificationType.SweetAlert
						});
					}
					// не получили ответ синхронного - список придется обновить пользователю позже вручную
					else {
						this.notificationService.success({
							title: "Заказ резерва",
							message: "Успешно отправлен. Результаты резервирования станут известны через некоторое время.",
							notificationType: NotificationType.SweetAlert
						});
					}
				}
			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при запросе резерва',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.Toast
				});
			}));
	}

	public withdrawResrvedDevice(requestId: number, requestDeviceId: number, comment: string, showSuccessMessage: boolean = true): Observable<ActionResult> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/cancel-reserve`;
		return this.http.post<ActionResult>(url, { requestId: requestId, requestDeviceId: requestDeviceId, comment: comment } as any)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка при запросе на снятие резерва',
						message: resp.errorDescription,
						notificationType: NotificationType.Toast
					});
				}
				else {
					if (showSuccessMessage)
						this.notificationService.success({
							title: "Снятие с резерва",
							message: "Запрос на снятие резерва успешно отправлен.",
							notificationType: NotificationType.SweetAlert
					});
				}
			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при запросе на снятие резерва',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.Toast
				});
			}));
	}

	public reParseRequestDevices(requestId: number, requestDeviceId: number = null, requestDevicePartId: number = null): Observable<ActionDataResult<RequestDeviceWarehouseRefillDto[]>> {
		let url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/reparse`;

		return this.postQuery(url, {
			requestId: requestId,
			requestDeviceId: requestDeviceId,
			requestDevicePartId: requestDevicePartId
		});
	}

	public notify1CReserveDevices(requestId: number, requestDeviceIds: number[] = []): Observable<ActionResult> {
		let url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/notify-1c-reserve-devices`;

		return this.postQuery(url, { requestId: requestId, requestDeviceIds: requestDeviceIds });
	}

	public confirmReceiptEquipmentByEngineer(requestId: number): Observable<any> {
		let url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/confirm-receipt-equipment-by-engineer`;

		return this.postQuery(url, { requestId: requestId });
	}

	public checkDeviceAvailableToMove(requestId: number): Observable<ActionDataResult<RequestDeviceWarehouseRefillDto[]>> {
		let url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/check-device-available-to-move`;

		return this.postQuery(url, {
			requestId: requestId
		});

	}

	public getFreeNomenclatures(requestDeviceQuery: IRequestDeviceNomenclatureQuery1c): Observable<GetAvailableNomenclaturesDto> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/available-nomenclatures`;
		return this.postQuery<GetAvailableNomenclaturesDto>(url, requestDeviceQuery, null)
			.pipe(filter(x => x.isSuccessful))
			.pipe(pluck('data'))
			;
	}

	public requestDevicesSerialNumbersFrom1C(requestId: number, activityId: number): Observable<ActionDataResult<SerialNumbersResult>> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/serial-numbers`;
		return this.postQuery<SerialNumbersResult>(url, { requestId: requestId, activityId: activityId }, null)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка при запросе в 1С',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при запросе в 1С',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}));
	}

	public issueRequestDevices(requestId: number, formDevices: SerialNumbersFormDevice[], changeActivityStatusModel: ChangeActivityStatusModel): Observable<ActionResult> {
		const devices = formDevices.map(formDevice => {
			return {
				requestDeviceId: formDevice.device.requestDeviceId,
				serialNumber: formDevice.inputValue
			};
		});

		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/issue-devices`;
		return this.http.post<ActionResult>(url,
			{
				requestId: requestId,
				devices: devices,
				changeActivityStatusCommand: changeActivityStatusModel
			})
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка при выдаче оборудования',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при выдаче оборудования',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}));
	}

	getRequestDevicesWithParts(requestId: number, activityId: number, status: string = null, shouldfFindWarehouseCells: boolean = false): Observable<ActionDataResult<SerialNumbersResult>> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/request-devices-with-parts`;

		const data = { requestId: requestId, activityId: activityId, shouldfFindWarehouseCells: shouldfFindWarehouseCells };
		if (status) {
			data['status'] = status;
		}

		return this.http.post<ActionDataResult<SerialNumbersResult>>(url, data)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка при получении оборудования и комплектующих',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при получении оборудования и комплектующих',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}));
	}

	logSerialNumbers(requestId: number, serialNumbers: string[]): Observable<ActionResult> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/log-validated-serial-numbers`;
		return this.http.post<ActionResult>(url, { requestId: requestId, serialNumbers: serialNumbers, comment: 'Оборудование получено' })
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка при выдаче оборудования',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при выдаче оборудования',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}));
	}

	public getExpertiseActForm(requestId: number, activityId: number): Observable<ActionDataResult<ExpertiseActFormList>> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/expertise-act-form`;
		return this.http.post<ActionDataResult<ExpertiseActFormList>>(url, { requestId: requestId, activityId: activityId })
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка при загрузке формы для проведения экспертизы оборудования',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при загрузке формы для проведения экспертизы оборудования',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}));
	}

	acceptReturnedDevices(requestId: number, devices: ReturnedDevice[]): Observable<ActionResult> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/accept-returned-devices`;
		return this.postQuery(url, { requestId: requestId, devices: devices });
	}

	public saveExpertiseData(data: ExpertiseActFormItem): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/save-expertise-act`, data);
	}

	public createFromDevice(requestId: number, deviceId: number): Observable<ActionDataResult<number>> {
		return this.postQuery<number>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/create-from-device`, {
			requestId: requestId,
			deviceId: deviceId
		});
	}

	public deleteFromDevice(requestId: number, deviceId: number): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/delete-from-device`, {
			requestId: requestId,
			deviceId: deviceId
		});
	}

	public getAvailableSerialNumbers(requestId: number, serialNumbers: string[], nomenclatures: string[], needToShowPopup: boolean = true): Observable<ActionDataResult<AvailableUnavailableSerialNumber>> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-available-serial-numbers`;
		return this.postQuery<AvailableUnavailableSerialNumber>(url, { requestId: requestId, serialNumbers: serialNumbers, nomenclatures: nomenclatures }, null, false)
			.pipe(tap(resp => {
				if (!resp.isSuccessful && needToShowPopup) {

					if (resp.code == ErrorCodes1C.unsuccessfulDeviceReservation && resp.data.unavailableSerialNumbers.length > 0) {
						this.notificationService.info({
							title: 'Информация',
							message: `С/н ${resp.data.unavailableSerialNumbers[0].serialNumber} не может быть зарезервирован.<br>Статус: ${resp.data.unavailableSerialNumbers[0].status}`,
							notificationType: NotificationType.SweetAlert, enableHtml: true, showConfirmButton: false
						});
					} else {
						this.notificationService.error({
							title: 'Ошибка при запросе в 1С',
							message: `ErrorCode: ${resp.code} ErrorMessage: ${resp.errorDescription}`,
							notificationType: NotificationType.SweetAlert
						});
					}
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при запросе в 1С',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}));
	}

	//#region Удаление документов в 1С

	public deleteDocuments(requestId: number, comment: string): Observable<ActionResult> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/delete-documents`;
		return this.http.post<ActionResult>(url, { requestId: requestId, userComment: comment } as any)
			.pipe(tap(resp => {
				if (resp.code != 0) {
					this.notificationService.error({
						title: 'Ошибка при удалении документов в 1С',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
				else {
					this.notificationService.success({
						title: "Удаление документов",
						message: "Удалены документы из 1C ERP.",
						notificationType: NotificationType.SweetAlert
					});
				}
			}, errorResponse => {
				this.notificationService.error({
					title: 'Ошибка при удалении документов в 1С',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}));
	}

	//#endregion 

	public getRequestComponents(requestId: number, deviceStatusId: number): Observable<ArticleDto[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${requestId}/${deviceStatusId}/get-request-components`);
	}
}
