import { Observable } from "rxjs";
import { DataService } from "../../core/services/data.service";
import { PerformerUserContragent } from "../models/contragent/performer-user-contragent";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { NotificationService } from "../../core/services/notification.service";
import { ActionResult } from "../models/core/ActionResult";
import { Injectable } from "@angular/core";

@Injectable()
export class PerformerUserContragentsService extends DataService<PerformerUserContragent, PerformerUserContragent> {

    constructor(http: HttpClient, notificationService: NotificationService) {
        super(http, notificationService);
        this.controllerName = 'performer-user-contragents';
    }

    public getPerformerUsers(contragentId: number): Observable<PerformerUserContragent[]> {
		return this.getQuery<PerformerUserContragent[]>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/performer-users/${contragentId}`);
	}

    public delete(performerUserContragent: PerformerUserContragent): Observable<ActionResult> {
        return this.postQuery<ActionResult>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/delete`, 
            performerUserContragent
        );
    }
}