import { Component, Input, Output, EventEmitter, TemplateRef, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ActivityWorkflowTransition } from '../../../shared/models/activity-workflow/activity-workflow-transition';
import { ActivityWorkflowTransitionType } from '../../../shared/models/activity-workflow/activity-workflow-transition-type';
import { ActivityChangeStatus } from '../../../shared/models/activity-workflow/activity-change-status';
import { ActivitiesService } from '../../../shared/services/activities.service';
import { AppService } from '../../../app.service';
import { Activity } from '../../../shared/models/activity/activity';
import { SetComment } from '../../../shared/components/workflow-transitions/set-comment/set-comment';
import { DialogService } from '@progress/kendo-angular-dialog';
import { SetCommentModel } from '../../../shared/components/workflow-transitions/set-comment/set-comment.model';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { SetSn } from '../../../shared/components/workflow-transitions/set-sn/set-sn';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivityStatusEnum, RequestInitTypeEnum } from '../../../shared/enums';
import { SetDeviceSerialNumbersComponent } from '../../../shared/components/workflow-transitions/set-device-serial-numbers';
import { SetActivityReasonComponent } from '../../../shared/components/workflow-transitions/set-activity-reason/set-activity-reason.component';
import { RequestType } from '../../../shared/enums/request-type.enum';
import { SecurityService } from '../../../core/services/security.service';
import { RequestDevicesService } from '../../../shared/services/request-devices.service';
import { UsersService } from '../../../admin/users/users.service';
import { ActivityType } from '../../../shared/enums/activity-type.enum';
import { SetCarrier } from '../../../shared/components/workflow-transitions/set-carrier/set-carrier';
import { SerialNumbersValidationForm } from '../serial-numbers-validation-form/serial-numbers-validation-form';
import { SerialNumbersFormDevice } from '../../../shared/models/device/serial-numbers-1C/serial-numbers-form-device';
import { SerialNumbersFormPart } from '../../../shared/models/device/serial-numbers-1C/serial-numbers-form-part';
import { SerialNumbersResult } from '../../../shared/models/device/serial-numbers-1C/serial-numbers-result';
import { SetSerialNumbersFormDevice } from '../../../shared/models/device/set-serial-numbers-form-device';
import { RequestsService } from '../../../shared/services/requests.service';
import { DeviceStatus } from '../../../shared/enums/device-status.enum';
import { SendStateWithLogic } from '../../../shared/models/request/send-state-with-logic';
import { UploadFile } from '../../../shared/models/attachment';
import { v4 as uuidv4 } from 'uuid';
import { CommentKindEnum } from '../../../shared/enums/comment-kind.enum';
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { SetSnReceive } from '../../../shared/components/workflow-transitions/set-sn-receive/set-sn-receive.component';
import { NewRequestDevice } from '../../../shared/models/request/new-request/new-request-device';
import { CheckDevicesAtWarehouseComponent } from '../../../shared/components/workflow-transitions';
import { ReserveResult } from '../../../shared/enums/reserve-result.enum';
import { SetSnComponents } from '../../../shared/components/workflow-transitions/set-sn-components/set-sn-components';
import { WorkEndedSuccessInstall } from '../../../shared/components/workflow-transitions/work-ended-success-install/work-ended-success-install';
import { CompleteWorkUninstallNew } from '../../../shared/components/workflow-transitions/complete-work-uninstall-new/complete-work-uninstall-new.component';
import { DeliveryOfEquipment } from '../../../shared/components/workflow-transitions/delivery-of-equipment/delivery-of-equipment';
import { Request } from '../../../shared/models/request/request';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { SetActivityReasonForStoppingComponent } from '../../../shared/components/workflow-transitions/set-activity-reason-for-stopping/set-activity-reason-for-stopping.component';
import { SetActivityReasonForStoppingModel } from '../../../shared/components/workflow-transitions/set-activity-reason-for-stopping/models/SetActivityReasonForStoppingModel';
import { DevicesService } from '../../../shared/services/devices.service';
import { CheckDevicesAtWarehouseMobileComponent } from '../../../shared/components/workflow-transitions/check-devices-at-warehouse-mobile/check-devices-at-warehouse-mobile.component';
import { Subject } from 'rxjs';
import { NewCommentModel } from '../../../shared/models/request/comments/new-comment-model';
import { SetPlannedDateComponent } from '../../../shared/components/workflow-transitions/set-planned-date/set-planned-date.component';
import { RequestBwt } from '../../../shared/models/request/request-bwt';
import { SetupDeviceSerial } from '../../../shared/components/workflow-transitions/setup-device-serial/setup-device-serial';
import { FinishActivityWithDevicesServices } from '../../../shared/components/workflow-transitions/finish-activity-with-devices-services/finish-activity-with-devices-services';

@Component({
	selector: 'activity-workflow',
	templateUrl: './activity-workflow.component.html',
	styleUrls: [
		'./activity-workflow.component.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class ActivityWorkflowComponent implements OnInit {

	private serialNumbersFormDevices: SetSerialNumbersFormDevice[] = [];

	@ViewChild("activitiesButtomSheetList") activitiesButtomSheetList: TemplateRef<any>;
	@ViewChild("selectMobileActivityAction") selectMobileActivityAction: ElementRef;

	@Input() activity: Activity;
	@Input() activities: Activity[];
	@Input() request: RequestBwt;
	@Input() initTypeId: number;
	@Input()
	requestId: number;

	@Input()
	customerContragentId: number;
	@Input()
	customerContragentIntegraCode: string;
	@Input()
	requestTypeId: number;
	@Input() entityName: string;

	@Input()
	recipientWarehouseId: number;

	@Input()
	serviceCenterId: number;

	@Input()
	warehouseTransferRequestSerialNumberExists: boolean = false;

	@Input()
	isSameWarehouses: boolean = false;

	@Input() stoppingReasons: KeyValueObject[] = [];

	@Input()
	workflowTransitions: ActivityWorkflowTransition[] = [];
	workflowTransitionsInProgress: number[] = [];
	dataLoading: boolean = false;
	activitiesButtomSheetListVisibility: boolean = false;

	@Output()
	onChangeStatus: EventEmitter<any> = new EventEmitter();

	selectedWorkflow: ActivityWorkflowTransition = null;
	selectedWorkflowResult: any = null;
	selectedWorkflowRequestType: RequestType = null;

	uploadAttachments: UploadFile[] = [];
	activityResults: KeyValueObject[] = [];
	device: NewRequestDevice;
	devices: NewRequestDevice[] = [];
	toUninstallDevices: NewRequestDevice[] = [];
	devicesForCheckReturnUnusedEquipment: NewRequestDevice[] = [];

	showDeliveryOfEquipmentForm: boolean = true;

	constructor(
		public devicesService: DevicesService,
		private activitiesService: ActivitiesService,
		private appService: AppService,
		private dialogService: DialogService,
		private notificationService: NotificationService,
		private bottomSheet: MatBottomSheet,
		private securityService: SecurityService,
		private requestDevicesService: RequestDevicesService,
		private usersService: UsersService,
		protected requestsService: RequestsService
	) {
	}

	ngOnInit(): void {

		this.requestsService.getRequestDevices(this.requestId).subscribe(result => {
			this.devicesForCheckReturnUnusedEquipment = result
				.filter(f => ![ReserveResult.reserveCanceled, ReserveResult.requestForReserveCancelling].includes(f.reserveResultId));
		});

		this.requestsService.getRequestUninstallDevices(this.requestId).subscribe(result => {
			this.toUninstallDevices = result.filter(x =>
				x.deviceStatusId === DeviceStatus.inRequest || x.deviceStatusId === DeviceStatus.toUninstall)
		});
	}

	workflowTransitionClick(transition: ActivityWorkflowTransition, event: MouseEvent) {
		if (this.bottomSheet) {
			this.bottomSheet.dismiss();
		}

		if (event) {
			event.preventDefault();
		}

		if (transition.errorText) {
			this.notificationService.error({
				title: 'Запрещенный переход',
				message: transition.errorText,
				notificationType: NotificationType.SweetAlert
			});
			
			return;
		}

		if (this.initTypeId !== RequestInitTypeEnum.blockChain &&
			transition.activityStatusToId === ActivityStatusEnum.workStarted &&
			!this._actionHasHistoryWithStatusId(ActivityStatusEnum.visit) &&
			this.activity.activityTypeId !== <number>ActivityType.techSupport) {

				this.changeStatus(transition);

			/* this.notificationService.confirmation({
				title: this.entityName,
				message: 'Вы не отметили прибытие на объект. Работы ведутся без выезда?',
				type: 'question',
				confirmButtonText: 'Да',
				cancelButtonText: 'Нет',
				showCloseButton: true
			}, () => {
				
			}); */

		} else if (this.initTypeId !== RequestInitTypeEnum.blockChain &&
			transition.activityStatusToId === ActivityStatusEnum.equipmentIssuedFromWarehouse) {
			this.issueDevicesFromWarehouse(transition);

		} else if (transition.activityStatusFromId === <number>ActivityStatusEnum.awaitingReturnToWarehouse &&
			transition.activityStatusToId === <number>ActivityStatusEnum.devicesAcceptedByWarehouse) {
			this.acceptDevicesFromEngineerOnCancellationRequest(transition);

		} else if (this.initTypeId !== RequestInitTypeEnum.blockChain &&
			transition.activityStatusToId === ActivityStatusEnum.devicesReceived) {
			this.acceptDevicesFromWarehouse(transition);

		} else if (transition.description) {
			this.notificationService.info({
				title: 'Информация',
				message: transition.description,
				notificationType: NotificationType.SweetAlert,
				confirmationFunction: () => {
					this.changeStatus(transition);
				}
			});
		} else {
			this.changeStatus(transition);
		}
	}

	changeStatus(transition: ActivityWorkflowTransition) {
		switch (transition.activityWorkflowTransitionTypeId) {

			case ActivityWorkflowTransitionType.ActionWithCommentTransition: {

				const dialogRef = this.dialogService.open({
					content: SetComment,
					width: this.isMobileDevice ? '90%' : '500px'
				});

				const customerObjectForm = <SetComment>dialogRef.content.instance;

				customerObjectForm.onContinueEvent.subscribe((setCommentModel: SetCommentModel) => {
					this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId, setCommentModel.commentText, null, null, null, null, setCommentModel.commentKind));
				});
				break;
			}
			case ActivityWorkflowTransitionType.ActionWithEquipmentSnInputTransition: {

				let showComponentsArticles = this.initTypeId === <number>RequestInitTypeEnum.blockChain &&
					(this.requestTypeId === <number>RequestType.install ||
						this.requestTypeId === <number>RequestType.installWithDismantle ||
						this.requestTypeId === <number>RequestType.changeConfig ||
						this.requestTypeId === <number>RequestType.serviceOrReplace ||
						this.requestTypeId === <number>RequestType.service);

				let desktopWidth = showComponentsArticles ? '750px' : '500px';

				const dialogRef = this.dialogService.open({
					content: SetupDeviceSerial,
					width: '80%',
					height: '750px',
					title: `Переход в статус '${transition.transitionName}'`
				});

				const setSnForm = <SetupDeviceSerial>dialogRef.content.instance;

				setSnForm.requestId = this.requestId;
				setSnForm.activityId = this.activity.activityId;

				setSnForm.onCancelEvent.subscribe(() => {
					dialogRef.close();
				})
				setSnForm.onContinueEvent.subscribe(() => {
					this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId, null, null));
					dialogRef.close();
				});

				break;
			}

			case ActivityWorkflowTransitionType.setDeviceSerialNumbers: {
				this._setDeviceSerialNumbers(transition);
				break;
			}

			case ActivityWorkflowTransitionType.setReason: {

				const setDeviceSerialNumbersDialogRef = this.dialogService.open({
					title: `Переход в статус '${transition.transitionName}'`,
					content: SetActivityReasonComponent,
					width: 600
				});

				const setActivityReasonComponent = <SetActivityReasonComponent>setDeviceSerialNumbersDialogRef.content.instance;
				setActivityReasonComponent.activityStatusId = transition.activityStatusToId;
				setActivityReasonComponent.onContinueEvent.subscribe((response: { activityReasonId: number, commentText: string }) => {
					this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId, response.commentText, response.activityReasonId));
				});
				break;
			}

			case ActivityWorkflowTransitionType.ChoosingTransportCompanyAndSpecifyingTrackNumber: {
				const dialogRef = this.dialogService.open({
					content: SetCarrier,
					width: this.isMobileDevice ? '60%' : '30%'
				});

				const setCarrierForm = <SetCarrier>dialogRef.content.instance;

				setCarrierForm.requestId = this.requestId;
				setCarrierForm.activityId = this.activity.activityId;

				setCarrierForm.onContinueEvent.subscribe(() => {
					this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId));		
				});

				break;
			}

			case ActivityWorkflowTransitionType.actAttachment: {
				const dialogRef = this.dialogService.open({
					content: SetCarrier,
					width: this.isMobileDevice ? '60%' : '30%'
				});

				const setCarrierForm = <SetCarrier>dialogRef.content.instance;

				setCarrierForm.requestId = this.requestId;
				setCarrierForm.activityId = this.activity.activityId;

				setCarrierForm.onContinueEvent.subscribe(() => {
					this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId));
				});

				break;
			}

			case ActivityWorkflowTransitionType.equipmentSnInputForReturn: {
				this.usersService.checkTransactionAccount().subscribe(res => {

					let validRemoteInstallTransition = this.requestTypeId === <number>RequestType.remoteInstall
						&& !(transition.activityStatusToId == ActivityStatusEnum.acceptanceOfReturnToWarehouse && transition.activityStatusFromId == ActivityStatusEnum.acceptanceOfReturnToWarehouse);

					let showComponentsArticles = this.initTypeId === <number>RequestInitTypeEnum.blockChain &&
						(this.requestTypeId === <number>RequestType.install ||
							this.requestTypeId === <number>RequestType.installWithDismantle ||
							validRemoteInstallTransition ||
							this.requestTypeId === <number>RequestType.uninstallOld ||
							this.requestTypeId === <number>RequestType.changeConfig ||
							this.requestTypeId === <number>RequestType.serviceOrReplace ||
							this.requestTypeId === <number>RequestType.service);

					let desktopWidth = showComponentsArticles ? '750px' : '600px';

					if (!res.isError) {
						const dialogRef = this.dialogService.open({
							content: SetSnReceive,
							width: this.isMobileDevice ? '90%' : desktopWidth
						});

						const setSnReceiveForm = <SetSnReceive>dialogRef.content.instance;

						setSnReceiveForm.requestId = this.requestId;
						setSnReceiveForm.recipientWarehouseId = this.recipientWarehouseId;
						setSnReceiveForm.serviceCenterId = this.serviceCenterId;
						setSnReceiveForm.requestTypeId = this.requestTypeId;
						setSnReceiveForm.initTypeId = this.initTypeId;
						setSnReceiveForm.activityStatusFromId = transition.activityStatusFromId;
						setSnReceiveForm.showComponentsArticles = showComponentsArticles;
						setSnReceiveForm.contragentId = this.customerContragentId;
						setSnReceiveForm.onContinueEvent.subscribe(componentsArticles => {
							this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId, null, null, componentsArticles));
						});
					} else {
						this.notificationService.info({
							title: 'Проверка пользователя',
							message: res.errorMessage,
							notificationType: NotificationType.SweetAlert
						});
					}
				});
				break;
			}

			case ActivityWorkflowTransitionType.deviceToWarehouseAction:
				this._checkDevicesAtWarehouse(transition, DeviceStatus.deInstalled);
				break;

			case ActivityWorkflowTransitionType.receivingDeviceAtWarehouseAction:
				this._checkDevicesAtWarehouse(transition, DeviceStatus.deliveredToWarehouse);
				break;

			case ActivityWorkflowTransitionType.deviceReserveCancellationWithComment: {

				const dialogRef = this.dialogService.open({
					content: SetComment,
					width: this.isMobileDevice ? '90%' : '500px'
				});

				const commentForm = <SetComment>dialogRef.content.instance;

				const setCommentModel = new SetCommentModel('');
				setCommentModel.commentKind = CommentKindEnum.internal;
				commentForm.setCommentModel = setCommentModel;

				commentForm.hideCommentKind = true;

				commentForm.onContinueEvent.subscribe((setCommentModel: SetCommentModel) => {
					this.deviceReserveCancel(transition, setCommentModel.commentText);
				});
				break;
			}

			case ActivityWorkflowTransitionType.componentsEditing:
				const dialogRef = this.dialogService.open({
					content: SetSnComponents,
					width: this.isMobileDevice ? '90%' : '750px'
				});

				const setSnComponentsForm = <SetSnComponents>dialogRef.content.instance;
				setSnComponentsForm.requestTypeId = this.requestTypeId;
				setSnComponentsForm.requestId = this.requestId;
				setSnComponentsForm.activityStatusId = this.activity.statusId;
				setSnComponentsForm.onContinueEvent.subscribe(componentsArticles => {
					this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId, null, null, componentsArticles));
				});
				break;

			case ActivityWorkflowTransitionType.transitionWithWorkResult:
				this.showCompleteWorkInstall(transition);
				break;

			case ActivityWorkflowTransitionType.transitionWithDeliveryOfEquipment:
				const deliveryOfEquipmentDialogRef = this.dialogService.open({
					title: "Передача оборудования инженеру",
					content: DeliveryOfEquipment,
					width: this.isMobileDevice ? "90%" : "1200px"
				});

				var deliveryOfEquipmentDialog = <DeliveryOfEquipment>deliveryOfEquipmentDialogRef.content.instance;
				deliveryOfEquipmentDialog.requestId = this.requestId;
				deliveryOfEquipmentDialog.activityId = this.activity.activityId;
				deliveryOfEquipmentDialog.defaultScWarehouseName = this.request.defaultScWarehouseName;
				deliveryOfEquipmentDialog.defaultScWarehouseId = this.request.defaultScWarehouseId;
				deliveryOfEquipmentDialog.onContinueEmitter.subscribe(() => {
					this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId));
				});

				break;

			case ActivityWorkflowTransitionType.transitionWithApproveOfEquipment:
				this.requestDevicesService.confirmReceiptEquipmentByEngineer(this.requestId).subscribe(() => {
					this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId));
				});
				break;

			case ActivityWorkflowTransitionType.transitionWithReasonCommentAndDate:
				this.setAcitivityReasonForStoppingTransitionType(transition);
				break;

			case ActivityWorkflowTransitionType.transitionUninstallWithWorkResult:
				this.showTransitionUninstallWithWorkResult(transition);
				break;

			case ActivityWorkflowTransitionType.transitionInstallAndUninstallWithWorkResult:
				this.showTransitionInstallAndUninstallWithWorkResult(transition);
				break;

			case ActivityWorkflowTransitionType.transitionWithSuccessWorkEndWithoutEquipment:
				this.showTransitionWithSuccessWorkEndWithoutEquipment(transition);
				break;

			case ActivityWorkflowTransitionType.transitionInstallAndUninstallOptionalWithWorkResult:
				this.showTransitionInstallAndUninstallOptionalWithWorkResult(transition);
				break;

			case ActivityWorkflowTransitionType.transitionWithMasterData: {
				switch (this.requestTypeId) {
					case <number>RequestType.uninstall:
					case <number>RequestType.uninstallOld:
						{
							this.showTransitionUninstallWithWorkResult(transition);
							break;
						}
					case <number>RequestType.install:
					case <number>RequestType.installCRE:
						{
							this.showCompleteWorkInstall(transition);
							break;
						}
					default:
						break;
				}
			}
				break;
			case ActivityWorkflowTransitionType.transitionWithFull1CDocumentsDeletion: {
				this.delete1CDocumentsTransitionType(transition);
				break;
			}

			case ActivityWorkflowTransitionType.planned: {
				this.transitionSetPlannedDate(transition);
				break;
			}

			default:
				if ((this.requestTypeId == <number>RequestType.warehouseTransfer
					|| this.requestTypeId == <number>RequestType.foreighWarehouseTransfer
					|| this.requestTypeId == <number>RequestType.internalWarehouseTransfer)
					&& this.initTypeId == <number>RequestInitTypeEnum.blockChain
					&& transition.activityStatusToId == <number>ActivityStatusEnum.automaticAcceptanceAtConsigneesWarehouse) {

					this.switchOnTranslationFlag(transition.activityWorkflowTransitionId);
					this.requestsService.sendStateWithLogic(
						new SendStateWithLogic(this.requestId, "rsReceived_auto"))
						.subscribe(res => {
							if (res.isSuccessful) {
								this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId));
							}
							this.switchOffTranslationFlag(transition.activityWorkflowTransitionId);
						});

				} else {
					this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId));
				}
				break;
		}
	}

	private deviceReserveCancel(transition: ActivityWorkflowTransition, commentText: string) {
		this.requestsService.getRequestDevices(this.requestId).subscribe(devices => {
			if (devices.length === 0) {
				this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId, commentText));
			} else {
				devices.forEach(d => {
					if ([ReserveResult.reserveRequest, ReserveResult.pendingIssueToEngineer, ReserveResult.booked].includes(d.reserveResultId)) {
						this.requestDevicesService.withdrawResrvedDevice(this.requestId, d.requestDeviceId, "Запрос на снятие резерва при удаленном завершении работ", false).subscribe(r => {
							if (r.isSuccessful) {
								d.reserveResultId = ReserveResult.requestForReserveCancelling;

								if (!devices.some(d => [ReserveResult.reserveRequest, ReserveResult.pendingIssueToEngineer, ReserveResult.booked].includes(d.reserveResultId)))
									this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId, commentText));
							} else {
								this.notificationService.error({
									title: 'Ошибка при смене статуса',
									message: `Не удалось снять оборудование с резерва: ${r.errorDescription}`,
									notificationType: NotificationType.SweetAlert
								});
							}
						});
					} else {
						this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId, commentText));
					}
				});
			}
		});
	}

	private _checkDevicesAtWarehouse(transition: ActivityWorkflowTransition, deviceStatus: DeviceStatus): void {
		this.requestsService.getRequestUninstallDevices(this.requestId)
			.subscribe(result => {
				this.serialNumbersFormDevices = result
					.filter(x => x.deviceStatusId === <number>deviceStatus)
					.map(device => new SetSerialNumbersFormDevice(device));
				this.showCheckDevicesAtWarehouseForm(transition);
			});
	}

	private showCheckDevicesAtWarehouseForm(transition: ActivityWorkflowTransition) {

		if (transition.activityWorkflowTransitionTypeId === ActivityWorkflowTransitionType.receivingDeviceAtWarehouseAction){
			let devicesLoaded: Subject<void> = new Subject<void>();
			this.getDevicesForWarehouseActions(DeviceStatus.receivedAtWarehouse, DeviceStatus.deliveredToWarehouse, null, devicesLoaded);
				devicesLoaded.subscribe(() => {
					this.devices = this.devices.filter(x => x.shouldBeReturnedToWarehouse);
				});
		}
			
		else
			this.getDevicesForWarehouseActions(DeviceStatus.deliveredToWarehouse, DeviceStatus.deInstalled);

		let shortcutReturnEquipment = false;

		var hasWarehouseActivity = this.activities.some(a => a.activityTypeId === <number>ActivityType.warehouse);
		if (this.request.isOneRoleSC
			&& this.activity.activityTypeId == ActivityType.engineer
			&& !hasWarehouseActivity) {
			shortcutReturnEquipment = true;
		}

		setTimeout(() => {
			if (this.devices.length == 0 && this.activity.activityTypeId === ActivityType.warehouse) {
				if (this.devicesForCheckReturnUnusedEquipment.some(s => !!s.issuedSuccess && !!!s.isWrittenOff)) {
					this.setSnReceiveForChangeConfig(transition);
				} else {
					this.notificationService.error({
						title: 'Ошибка',
						message: `Оборудование для действия '${transition.transitionName}' отсутствует`,
						notificationType: NotificationType.SweetAlert
					});
				}

				return;
			}

			const dialogRef = this.dialogService.open({
				title: `Переход в статус '${transition.transitionName}'`,
				content: CheckDevicesAtWarehouseComponent,
				width: this.appService.isMobileDevice ? '90%' : 600
			});

			const instance = <CheckDevicesAtWarehouseComponent>dialogRef.content.instance;
			instance.newActivityStatusId = transition.activityStatusToId;
			instance.requestId = +this.requestId;
			instance.device = this.device;
			instance.devices = this.devices;
			instance.serviceCenterId = this.serviceCenterId;
			instance.shortcutReturnEquipment = shortcutReturnEquipment;
			instance.warehouseId = this.recipientWarehouseId;
			instance.contragentId = this.customerContragentId;
			instance.showWarehouseForm =
				transition.activityWorkflowTransitionTypeId === ActivityWorkflowTransitionType.receivingDeviceAtWarehouseAction &&
				this.initTypeId !== RequestInitTypeEnum.blockChain;

			instance.contragentIntegraCode = this.customerContragentIntegraCode;

			instance.continueEmitter
				.subscribe(result => {
					this.requestsService.getRequestDevices(this.requestId).subscribe(res => {
						let devicesForCheckReturnUnusedEquipmentFiltered = res.filter(f => !!f.issuedSuccess && !!!f.isWrittenOff
							&& ![ReserveResult.reserveCanceled
								, ReserveResult.requestForReserveCancelling
								, ReserveResult.returnedToWarehouse
								, ReserveResult.awaitingReturnToWarehouse
								, ReserveResult.notFound
								, ReserveResult.notBooked].includes(f.reserveResultId));

						if (devicesForCheckReturnUnusedEquipmentFiltered.length > 0
							&& this.activity.activityTypeId === ActivityType.warehouse) {
							this.setSnReceiveForChangeConfig(transition);
						} else if (shortcutReturnEquipment) {
							this.shortcutReturnEquipment(transition);
						} else {
							this.changeStatusCore(transition, new ActivityChangeStatus(+this.activity.activityId, transition.activityStatusToId))
						};
					});
				}
				);
		}, 1000);
	}

	setSnReceiveForChangeConfig(transition: ActivityWorkflowTransition): void {
		this.usersService.checkTransactionAccount().subscribe(res => {

			let validRemoteInstallTransition = this.requestTypeId === <number>RequestType.remoteInstall
				&& !(transition.activityStatusToId == ActivityStatusEnum.acceptanceOfReturnToWarehouse && transition.activityStatusFromId == ActivityStatusEnum.acceptanceOfReturnToWarehouse);

			let showComponentsArticles = this.initTypeId === <number>RequestInitTypeEnum.blockChain &&
				(this.requestTypeId === <number>RequestType.install ||
					validRemoteInstallTransition ||
					this.requestTypeId === <number>RequestType.uninstallOld ||
					this.requestTypeId === <number>RequestType.changeConfig ||
					this.requestTypeId === <number>RequestType.serviceOrReplace ||
					this.requestTypeId === <number>RequestType.service);

			let desktopWidth = showComponentsArticles ? '750px' : '600px';

			if (!res.isError) {
				const dialogRef = this.dialogService.open({
					content: SetSnReceive,
					width: this.isMobileDevice ? '90%' : desktopWidth
				});

				const setSnReceiveForm = <SetSnReceive>dialogRef.content.instance;

				setSnReceiveForm.requestId = this.requestId;
				setSnReceiveForm.recipientWarehouseId = this.recipientWarehouseId;
				setSnReceiveForm.serviceCenterId = this.serviceCenterId;
				setSnReceiveForm.requestTypeId = this.requestTypeId;
				setSnReceiveForm.initTypeId = this.initTypeId;
				setSnReceiveForm.activityStatusFromId = transition.activityStatusFromId;
				setSnReceiveForm.showComponentsArticles = showComponentsArticles;
				setSnReceiveForm.contragentId = this.customerContragentId;
				setSnReceiveForm.onContinueEvent.subscribe(componentsArticles => {
					this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId, null, null, componentsArticles));
				});
			} else {
				this.notificationService.info({
					title: 'Проверка пользователя',
					message: res.errorMessage,
					notificationType: NotificationType.SweetAlert
				});
			}
		});
	}

	isDisabledTransition(transition: ActivityWorkflowTransition): boolean {

		//Раскомментировать как выкатим в прод задачу LTER-300
		// if (transition.activityStatusFromId === ActivityStatusEnum.awaitingDeliveryOfEquipment &&
		// 	transition.activityStatusToId === ActivityStatusEnum.equipmentIssuedFromWarehouse) {
		// 	return !this.securityService.isAdmin() && !this.securityService.isStorekeeper();
		// }

		return false;
	}

	private _setDeviceSerialNumbers(transition: ActivityWorkflowTransition): void {

		if (this.serialNumbersFormDevices.length === 0) {
			this.requestsService.getRequestUninstallDevices(this.requestId)
				.subscribe(result => {

					result = result
						.filter(x => (result.filter(z => z.serialNumber === x.serialNumber).length == 1 &&
							!result.some(z => z.serialNumber === x.serialNumber && x.deviceStatusId === <number>DeviceStatus.deInstalled)) ||
							(result.filter(z => z.serialNumber === x.serialNumber).length > 1 &&
								result.filter(z => z.serialNumber === x.serialNumber).every(z => x.deviceStatusId !== <number>DeviceStatus.deInstalled)));

					if (result.length === 0) {
						this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId));
					} else {
						this.serialNumbersFormDevices = result.map(device => new SetSerialNumbersFormDevice(device));
						this.showDeviceSerialNumbersForm(transition);
					}

				});
		} else {
			this.showDeviceSerialNumbersForm(transition);
		}

	}

	private showDeviceSerialNumbersForm(transition: ActivityWorkflowTransition) {

		const serialNumberFormDevice = this.serialNumbersFormDevices
			.filter(x => !x.processed && x.device.deviceStatusId === <number>DeviceStatus.inRequest && !this.serialNumbersFormDevices
				.some((z => z.device.serialNumber === x.device.serialNumber && z.device.deviceStatusId === <number>DeviceStatus.deInstalled) as any));

		if (serialNumberFormDevice.length === 0) {
			this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId));
			return;
		}

		const setDeviceSerialNumbersDialogRef = this.dialogService.open({
			title: `Переход в статус '${transition.transitionName}'`,
			content: SetDeviceSerialNumbersComponent,
			width: this.appService.isMobileDevice ? '90%' : 600
		});

		const setDeviceSerialNumbersForm = <SetDeviceSerialNumbersComponent>setDeviceSerialNumbersDialogRef.content.instance;
		setDeviceSerialNumbersForm.requestId = this.activity.requestId;
		setDeviceSerialNumbersForm.device = serialNumberFormDevice.length > 0 ? serialNumberFormDevice[0].device : null;
		setDeviceSerialNumbersForm.devices = serialNumberFormDevice.map(m => m.device);
		setDeviceSerialNumbersForm.reinitForm = true;
		setDeviceSerialNumbersForm.continueEmitter.subscribe(() =>
			this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId))
		);

		// setDeviceSerialNumbersForm.checkNextSerialNumberEmitter
		// 	.subscribe(() => {
		// 		if (serialNumberFormDevice.length > 0) {
		// 			serialNumberFormDevice[0].processed = true;
		// 		}

		// 		const areAllProcessedDevices = this.serialNumbersFormDevices
		// 			.filter(x => !x.processed && x.device.deviceStatusId === <number>DeviceStatus.inRequest && !this.serialNumbersFormDevices
		// 									.some((z => z.device.serialNumber === x.device.serialNumber && z.device.deviceStatusId === <number>DeviceStatus.deInstalled) as any))
		// 			.length === 0;
		// 		if (areAllProcessedDevices) {
		// 			this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId));
		// 			return;
		// 		} else {
		// 			this._showSNConfirmation(transition);
		// 		}
		// 	});
	}

	private _showSNConfirmation(transition: ActivityWorkflowTransition): void {
		this.notificationService
			.confirmation({
				message: 'Есть ещё оборудование для проверки серийного номера?',
				type: 'question',
				confirmButtonText: 'Да',
				cancelButtonText: 'Нет',
				showCloseButton: true
			},
				() => this._setDeviceSerialNumbers(transition),
				() => this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId)));
	}
	changeStatusCore(transition: ActivityWorkflowTransition, changeStatusModel: ActivityChangeStatus, onResult: () => void = null) {

		this.switchOnTranslationFlag(transition.activityWorkflowTransitionId);
		this.activitiesService.changeStatus(this.activity.activityId, transition.activityStatusToId, changeStatusModel.comment, changeStatusModel.activityReasonId, transition.activityWorkflowTransitionId, changeStatusModel.componentsArticles, changeStatusModel.stoppedUntil, changeStatusModel.requestStoppingReasonId, changeStatusModel.commentKind)
			.subscribe(() => {
				this.switchOffTranslationFlag(transition.activityWorkflowTransitionId);

				if (this.onChangeStatus) {
					this.onChangeStatus.emit(transition);
				}

				if (onResult) {
					onResult();
				}
			}, () => {
				this.switchOffTranslationFlag(transition.activityWorkflowTransitionId);

				if (onResult) {
					onResult();
				}
			});
	}

	switchOnTranslationFlag(transitionId: number) {
		this.workflowTransitionsInProgress.push(transitionId);
	}

	switchOffTranslationFlag(transitionId: number) {
		const index = this.workflowTransitionsInProgress.indexOf(transitionId, 0);
		if (index > -1) {
			this.workflowTransitionsInProgress.splice(index, 1);
		}
	}

	get isMobileDevice(): boolean {
		return this.appService.isMobileDevice;
	}

	private _actionHasHistoryWithStatusId(typeId: number): boolean {
		if (!this.activity) {
			return false;
		}

		return this.activity.history.some(x => x.activityActionTypeId === typeId);
	}

	openActivitiesButtomSheet() {
		this.activitiesButtomSheetListVisibility = true;
		const bottomSheetRef = this.bottomSheet.open(this.activitiesButtomSheetList);

		bottomSheetRef.afterOpened().subscribe(() => {
			this.activitiesButtomSheetListVisibility = true;
		});

		bottomSheetRef.afterDismissed().subscribe(() => {
			this.activitiesButtomSheetListVisibility = false;
		});
	}

	activitiesButtomSheetListCancel(event: MouseEvent) {
		this.bottomSheet.dismiss();

		if (event) {
			event.preventDefault();
		}
	}

	private issueDevicesFromWarehouse(transition: ActivityWorkflowTransition): void {
		this.requestDevicesService.requestDevicesSerialNumbersFrom1C(this.requestId, this.activity.activityId)
			.subscribe(result => {
				if (result.isSuccessful) {
					const formWithDialog = this.createSerialNumbersValidationFormDialog(true, 'Выдать', result.data);

					formWithDialog.form.onIssueValidatedDevices.subscribe(
						data => {
							this.requestDevicesService.issueRequestDevices(
								this.requestId,
								data.formDevices,
								{
									activityId: this.activity.activityId,
									newActivityStatusId: transition.activityStatusToId
								})
								.subscribe(result => {

									if (this.onChangeStatus) {
										this.onChangeStatus.emit(transition);
									}

									data.loading = false;
									formWithDialog.dialogRef.close();
								});
						});
				}
			});
	}

	private acceptDevicesFromWarehouse(transition: ActivityWorkflowTransition): void {
		this.requestDevicesService.getRequestDevicesWithParts(this.requestId, this.activity.activityId)
			.subscribe(result => {
				const formWithDialog = this.createSerialNumbersValidationFormDialog(false, 'Приём завершён', result.data);

				formWithDialog.form.onIssueValidatedDevices.subscribe(
					data => {
						this.changeStatusCore(
							transition,
							new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId),
							() => {
								this.requestDevicesService
									.logSerialNumbers(this.requestId, data.formDevices.map(device => device.inputValue))
									.subscribe(result => {
										data.loading = false;
										formWithDialog.dialogRef.close();
									});
							});
					});
			});
	}

	private acceptDevicesFromEngineerOnCancellationRequest(transition: ActivityWorkflowTransition): void {
		this.requestDevicesService.getRequestDevicesWithParts(this.requestId, this.activity.activityId, 'Ожидает возврата на склад', true)
			.subscribe(result => {
				const formWithDialog = this.createSerialNumbersValidationFormDialog(false, 'Возврат принят', result.data);

				formWithDialog.form.showWarehouseCellsForDeviceReturn = true;

				formWithDialog.form.onIssueValidatedDevices.subscribe(
					data => {
						this.requestDevicesService
							.acceptReturnedDevices(this.requestId, data.choosenCells)
							.subscribe(result => {
								if (result.isSuccessful) {
									this.changeStatusCore(
										transition,
										new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId),
										() => {
											formWithDialog.dialogRef.close();
										});

								}
							});
					});
			});
	}

	private createSerialNumbersValidationFormDialog(showWarehouseCells: boolean, onAcceptBtnContent: string, serialNumbers: SerialNumbersResult): any {
		const dialogRef = this.dialogService.open({ content: SerialNumbersValidationForm, width: '55%', height: '50%' });

		const serialNumbersValidationForm = <SerialNumbersValidationForm>dialogRef.content.instance;

		serialNumbersValidationForm.showWarehouseCells = showWarehouseCells;
		serialNumbersValidationForm.onAcceptBtnContent = onAcceptBtnContent;

		serialNumbersValidationForm.possibleWarehouseCells = serialNumbers.possibleWarehouseCells;
		serialNumbersValidationForm.formDevices = serialNumbers.devices.map((device, _) => new SerialNumbersFormDevice(device));
		serialNumbersValidationForm.formParts = serialNumbers.parts.map((part, _) => new SerialNumbersFormPart(part));
		serialNumbersValidationForm.initTypeId = this.initTypeId;

		return { form: serialNumbersValidationForm, dialogRef: dialogRef };
	}

	public changeStatusForSn() {
		this.changeStatusCore(this.selectedWorkflow, new ActivityChangeStatus(this.activity.activityId, this.selectedWorkflow.activityStatusToId));
	}


	public changeStatusForSerialNumber(commentText: string = '', shortcutReturnEquipment = false) {
		if (shortcutReturnEquipment) {
			this.shortcutReturnEquipment(this.selectedWorkflow);
		} else {
			this.changeStatusCore(this.selectedWorkflow, new ActivityChangeStatus(this.activity.activityId, this.selectedWorkflow.activityStatusToId, commentText));
		}
	}

	public changeStatusForComment() {
		this.changeStatusCore(this.selectedWorkflow, new ActivityChangeStatus(this.activity.activityId, this.selectedWorkflow.activityStatusToId, this.selectedWorkflowResult));
	}

	public changeStatusForReason(response: { activityReasonId: number, commentText: string }) {
		this.changeStatusCore(this.selectedWorkflow, new ActivityChangeStatus(this.activity.activityId, this.selectedWorkflow.activityStatusToId, response.commentText, response.activityReasonId));
	}

	public changeStatusWithWorkResult() {
		this.changeStatusCore(this.selectedWorkflow, new ActivityChangeStatus(this.activity.activityId, this.selectedWorkflow.activityStatusToId));
	}

	public changeStatusForDeliveryOfEquipment() {
		this.changeStatusCore(this.selectedWorkflow, new ActivityChangeStatus(this.activity.activityId, this.selectedWorkflow.activityStatusToId), () => {
			this.showDeliveryOfEquipmentForm = false;
		});
	}

	public refreshActivityStatus() {
		this.switchOffTranslationFlag(this.selectedWorkflow.activityWorkflowTransitionId);

		if (this.onChangeStatus) {
			this.onChangeStatus.emit(this.selectedWorkflow);
		}
	}

	public changeStatusForDefault() {
		if ((this.requestTypeId == <number>RequestType.warehouseTransfer
			|| this.requestTypeId == <number>RequestType.foreighWarehouseTransfer
			|| this.requestTypeId == <number>RequestType.internalWarehouseTransfer)
			&& this.initTypeId == <number>RequestInitTypeEnum.blockChain
			&& this.selectedWorkflow.activityStatusToId == <number>ActivityStatusEnum.automaticAcceptanceAtConsigneesWarehouse) {

			this.switchOnTranslationFlag(this.selectedWorkflow.activityWorkflowTransitionId);
			this.requestsService.sendStateWithLogic(
				new SendStateWithLogic(this.requestId, "rsReceived_auto"))
				.subscribe(res => {
					if (res.isSuccessful) {
						this.changeStatusCore(this.selectedWorkflow, new ActivityChangeStatus(this.activity.activityId, this.selectedWorkflow.activityStatusToId));
					}

					this.switchOffTranslationFlag(this.selectedWorkflow.activityWorkflowTransitionId);
				});

		} else {
			this.changeStatusCore(this.selectedWorkflow, new ActivityChangeStatus(this.activity.activityId, this.selectedWorkflow.activityStatusToId));
		}

	}

	public delete1CDocumentsTransitionType(transition: ActivityWorkflowTransition) {
		this.notificationService
			.confirmation({
				message: 'Откат активности приведет к полному удалению всех документов в 1С по заявке. Продолжить?',
				type: 'question',
				confirmButtonText: 'Да',
				cancelButtonText: 'Нет',
				showCloseButton: true
			},
				() => {
					this.switchOnTranslationFlag(transition.activityWorkflowTransitionId);
					this.requestDevicesService.deleteDocuments(this.requestId, "Удаление документов из перехода: Переход с полным удалением документов в 1С")
						.subscribe(result => {
							if (result.isSuccessful) {
								this.changeStatusCore(transition, new ActivityChangeStatus(
									this.activity.activityId,
									transition.activityStatusToId,
									"Выполнен откат активности с удалением всех документов по заказу клиента в 1C ERP"));
							} else {
								this.switchOffTranslationFlag(transition.activityWorkflowTransitionId);
							}
						});
				});
	}

	public resetActivityOption(): void {
		this.selectMobileActivityAction.nativeElement.options[0].selected = true;
		this.onSelectWorkflowTransaction(null);
	}

	public onSelectWorkflowTransaction(value: any) {
		this.selectedWorkflow = null;
		let selectedWorkflow = this.workflowTransitions.find(p => p.activityWorkflowTransitionId == value);
		if (!selectedWorkflow) {
			return;
		}

		if (selectedWorkflow.errorText) {
			this.notificationService.error({
				title: 'Запрещенный переход',
				message: selectedWorkflow.errorText,
				notificationType: NotificationType.SweetAlert
			});
			return;
		}

		switch (selectedWorkflow.activityWorkflowTransitionTypeId) {
			case ActivityWorkflowTransitionType.ActionWithEquipmentSnInputTransition: {
				this.selectedWorkflow = selectedWorkflow;
				this.selectedWorkflowRequestType = this.requestTypeId;
				switch (this.requestTypeId) {
					case <number>RequestType.warehouseTransfer:
					case <number>RequestType.foreighWarehouseTransfer:
					case <number>RequestType.internalWarehouseTransfer:
					case <number>RequestType.remoteInstall:
						this.usersService.checkTransactionAccount().subscribe(res => {

							if (res.isError) {
								this.selectedWorkflowRequestType = null;
								this.notificationService.info({
									title: 'Проверка пользователя',
									message: res.errorMessage,
									notificationType: NotificationType.SweetAlert
								});
							}
						});
						break;
				}

				break;
			}
			case ActivityWorkflowTransitionType.deviceToWarehouseAction: {
				this.deviceToWarehouseActionTransitionType(selectedWorkflow);
				break;
			}
			case ActivityWorkflowTransitionType.transitionWithWorkResult:
			case ActivityWorkflowTransitionType.setDeviceSerialNumbers: {
				if (this.serialNumbersFormDevices.length === 0) {
					this.requestsService.getRequestUninstallDevices(this.requestId).subscribe(result => {
						result = result
							.filter(x => (result.filter(z => z.serialNumber === x.serialNumber).length == 1 &&
								!result.some(z => z.serialNumber === x.serialNumber && x.deviceStatusId === <number>DeviceStatus.deInstalled)) ||
								(result.filter(z => z.serialNumber === x.serialNumber).length > 1 &&
									result.filter(z => z.serialNumber === x.serialNumber).every(z => x.deviceStatusId !== <number>DeviceStatus.deInstalled)));

						if (result.length > 0) {
							this.serialNumbersFormDevices = result.map(device => new SetSerialNumbersFormDevice(device));
							const serialNumberFormDevice = this.serialNumbersFormDevices
								.filter(x => !x.processed
									&& x.device.deviceStatusId === <number>DeviceStatus.inRequest
									&& !this.serialNumbersFormDevices
										.some((z => z.device.serialNumber === x.device.serialNumber && z.device.deviceStatusId === <number>DeviceStatus.deInstalled) as any));
							this.device = serialNumberFormDevice.length > 0 ? serialNumberFormDevice[0].device : null;

							if (!!serialNumberFormDevice) {
								this.devices = serialNumberFormDevice.map(m => m.device);
							}
						}

						this.selectedWorkflow = selectedWorkflow;
					});
				} else {
					this.selectedWorkflow = selectedWorkflow;
				}

				break;
			}
			case ActivityWorkflowTransitionType.transitionWithReasonCommentAndDate:
				this.setAcitivityReasonForStoppingTransitionType(selectedWorkflow);
				break;
			case ActivityWorkflowTransitionType.planned:
				this.transitionSetPlannedDate(selectedWorkflow);
				break;
			default: {
				this.selectedWorkflow = selectedWorkflow;
			}
		}
	}

	private getDevicesForWarehouseActions(ignoreStatus: DeviceStatus, neededStatus: DeviceStatus, selectedWorkflow: ActivityWorkflowTransition = null, notify: Subject<void> = null): void {
		this.requestsService.getRequestUninstallDevices(this.requestId).subscribe(result => {
			result = result
				.filter(x => (result.filter(z => z.serialNumber === x.serialNumber).length == 1 &&
					!result.some(z => z.serialNumber === x.serialNumber && x.deviceStatusId === <number>ignoreStatus)) ||
					(result.filter(z => z.serialNumber === x.serialNumber).length > 1 &&
						result.filter(z => z.serialNumber === x.serialNumber).every(z => x.deviceStatusId !== <number>ignoreStatus)));

			if (result.length > 0) {
				this.serialNumbersFormDevices = result.map(device => new SetSerialNumbersFormDevice(device));
				const serialNumberFormDevice = this.serialNumbersFormDevices
					.filter(x => !x.processed
						&& x.device.deviceStatusId === <number>neededStatus
						&& !this.serialNumbersFormDevices
							.some((z => z.device.serialNumber === x.device.serialNumber && z.device.deviceStatusId === <number>ignoreStatus) as any));
				this.device = serialNumberFormDevice.length > 0 ? serialNumberFormDevice[0].device : null;

				if (!!serialNumberFormDevice) {
					this.devices = serialNumberFormDevice.map(m => m.device);
				}
			}
			if (selectedWorkflow)
				this.selectedWorkflow = selectedWorkflow;

			if (notify) {
				notify.next();
			}
		});
	}

	get hasEditRequestClaim(): boolean {
		return this.securityService.hasClaim('cat-requests-edit');
	}

	handleFileInput(files: FileList) {
		for (let i = 0; i < files.length; i++) {
			let file = files[0];
			const reader = new FileReader();
			const self = this;

			reader.onload = function (readerEvt: any) {
				const binaryString = <string>readerEvt.target.result;
				const uid = uuidv4();
				const attachmentFile = new UploadFile();
				attachmentFile.uid = uid;
				attachmentFile.fileName = file.name;
				attachmentFile.uid = uid;
				attachmentFile.fileData = btoa(binaryString);

				self.uploadAttachments.push(attachmentFile);

				var fileData = `data:image/png;base64,${attachmentFile.fileData}`;

				// self.images.push({
				// 	title: attachmentFile.fileName,
				// 	altText: uid,
				// 	url: fileData,
				// 	thumbnailUrl: fileData,
				// });
			};

			reader.readAsBinaryString(file);
		}
	}

	public changeStatusForFileAndComment() {
		if (this.uploadAttachments.length === 0) {
			return;
		}
		this.requestsService.addComment({
			requestId: this.requestId,
			activityId: this.activity.activityId,
			text: "Добавил файл",
			uploadAttachments: this.uploadAttachments,
			commentKind: CommentKindEnum.internal,
			notifyCustomer: false,
			notifyEngineer: false,
			engineerNotifyWays: []
		}).subscribe(x => {
			this.uploadAttachments = [];
			if (x instanceof HttpResponse && x.type === HttpEventType.Response) {
				this.changeStatusCore(this.selectedWorkflow, new ActivityChangeStatus(this.activity.activityId, this.selectedWorkflow.activityStatusToId, this.selectedWorkflowResult));
			}
		});
	}

	public deviceReserveCancelMobile() {
		if (this.uploadAttachments.length !== 0) {
			this.requestsService.addComment({
				requestId: this.requestId,
				activityId: this.activity.activityId,
				text: "Добавил файл",
				uploadAttachments: this.uploadAttachments,
				commentKind: CommentKindEnum.internal,
				notifyCustomer: false,
				notifyEngineer: false,
				engineerNotifyWays: []
			}).subscribe(x => {
				this.uploadAttachments = [];
				if (x instanceof HttpResponse && x.type === HttpEventType.Response) {
					this.deviceReserveCancel(this.selectedWorkflow, this.selectedWorkflowResult);
				}
			});
		} else {
			this.deviceReserveCancel(this.selectedWorkflow, this.selectedWorkflowResult);
		}
	}

	public showTransitionWithSuccessWorkEndWithoutEquipment(transition: ActivityWorkflowTransition) {
		const dialogRef = this.dialogService.open({
			content: FinishActivityWithDevicesServices,
			width: '80%',
			height: '750px',
			title: `Переход в статус '${transition.transitionName}'`
		});

		const dialog = <FinishActivityWithDevicesServices>dialogRef.content.instance;

		dialog.requestId = this.request.requestId;
		dialog.requestTypeId = this.request.requestTypeId;
		dialog.activityId = this.activity.activityId;
		dialog.claimId = this.request.claimId;
		dialog.resolution = this.request.resolution;

		dialog.onCancelEvent.subscribe(() => {
			dialogRef.close();
		})
		dialog.onContinueEvent.subscribe(() => {
			dialogRef.close();
			this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId));
		})
	}

	public setActivityCommentTransitionType(transition: ActivityWorkflowTransition) {
		const setCommentWithCancelDialogRef = this.dialogService.open({
			content: SetComment,
			width: this.isMobileDevice ? '90%' : '500px',
		});

		const setCommentWithCancelDialog = <SetComment>setCommentWithCancelDialogRef.content.instance;
		setCommentWithCancelDialog.requestId = this.requestId;
		setCommentWithCancelDialog.activityId = this.activity.activityId;
		setCommentWithCancelDialog.nextActivityStatusId = transition.activityStatusToId;
		setCommentWithCancelDialog.showActivityResult = true;
		setCommentWithCancelDialog.useInstallationCompleteSuccessfull = this.isMobileDevice;

		setCommentWithCancelDialog.onContinueEvent.subscribe((setCommentModel: SetCommentModel) => {
			this.activitiesService.setActivityResult(this.activity.activityId, setCommentModel.activityResultId).subscribe(() => {
				this.activitiesService.getActivityResults(this.activity.activityId, transition.activityStatusToId).subscribe(resp => {
					if (!!resp) {
						let respKv = resp.map(m => new KeyValueObject(m.activityResultId, m.activityResult));
						this.activityResults = respKv;
					}
					let res = this.activityResults.find(f => f.id === setCommentModel.activityResultId);
					if (res !== null && res.name !== null && res.name !== "" && !!res.name) {
						var commentModel = new NewCommentModel;
						commentModel.text = res.name;
						commentModel.requestId = this.requestId;
						commentModel.commentKind = CommentKindEnum.general;
						this.requestsService.addComment(commentModel).subscribe(() => { });
					}
				});
				this.switchOnTranslationFlag(transition.activityWorkflowTransitionId);
				if (this.isMobileDevice) {
					this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId, setCommentModel.commentText));
				}
				else {
					this.devicesService.installationCompleteSuccessfull(this.requestId, [], this.activity.activityId).subscribe(() => {
						this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId, setCommentModel.commentText));
					});
				}
			});
		});
	}

	public setAcitivityReasonForStoppingTransitionType(transition: ActivityWorkflowTransition) {
		const dialog = this.dialogService.open({
			title: `Переход в статус '${transition.transitionName}'`,
			content: SetActivityReasonForStoppingComponent,
			width: '95%',
			height: '90%',
		});

		dialog.dialog.location.nativeElement.classList.add('dialog-abs');

		const setStopReasonAndDateForm = <SetActivityReasonForStoppingComponent>dialog.content.instance;
		setStopReasonAndDateForm.requestId = this.requestId;
		setStopReasonAndDateForm.activityId = this.activity.activityId;
		setStopReasonAndDateForm.requestCustomerContragentId = this.request.customerContragentId;
		setStopReasonAndDateForm.stoppingReasons = this.stoppingReasons;
		setStopReasonAndDateForm.onContinueEvent.subscribe((result: SetActivityReasonForStoppingModel) => {
			if (result.approvedStoppedDate) {
				result.approvedStoppedDate.setHours(result.approvedStoppedDate.getHours() - this.request.utcTimezoneShift);
			}
			this.changeStatusCore(transition, new ActivityChangeStatus(
				this.activity.activityId,
				transition.activityStatusToId,
				result.comment ? 'Комментарий: ' + result.comment : null,
				null,
				[],
				result.approvedStoppedDate,
				result.requestStoppingReasonId));
		});

	}

	public deviceToWarehouseActionTransitionType(transition: ActivityWorkflowTransition) {
		this.selectedWorkflow = transition;

		let shortcutReturnEquipment = false;

		var hasWarehouseActivity = this.activities.some(a => a.activityTypeId === <number>ActivityType.warehouse);
		if (this.request.isOneRoleSC
			&& this.activity.activityTypeId == ActivityType.engineer
			&& !hasWarehouseActivity) {
			shortcutReturnEquipment = true;
		}

		switch (this.requestTypeId) {
			case <number>RequestType.uninstall:
			case <number>RequestType.uninstallOld:
				{
					const dialog = this.dialogService.open({
						title: `Переход в статус '${transition.transitionName}'`,
						content: CheckDevicesAtWarehouseMobileComponent,
						width: '100%',
						height: '100%',
					});

					dialog.dialog.location.nativeElement.classList.add('dialog-abs');

					const setStopReasonAndDateForm = <CheckDevicesAtWarehouseMobileComponent>dialog.content.instance;
					setStopReasonAndDateForm.requestId = this.requestId;
					setStopReasonAndDateForm.shortcutReturnEquipment = shortcutReturnEquipment;
					setStopReasonAndDateForm.onCancelEvent.subscribe(() => {
						dialog.close();
						this.resetActivityOption();
					});
					setStopReasonAndDateForm.onContinueEvent.subscribe(() => {
						this.changeStatusForSerialNumber('', shortcutReturnEquipment);
					});

					break;
				}
			default:
				{
					let devicesLoaded: Subject<void> = new Subject<void>();
					this.getDevicesForWarehouseActions(DeviceStatus.deliveredToWarehouse, DeviceStatus.deInstalled, null, devicesLoaded);
					devicesLoaded.subscribe(() => {
						const dialog = this.dialogService.open({
							title: `Переход в статус '${transition.transitionName}'`,
							content: CheckDevicesAtWarehouseComponent,
							width: '100%',
							height: '100%',
						});

						dialog.dialog.location.nativeElement.classList.add('dialog-abs');

						const checnDevicesAtWarehouseForm = <CheckDevicesAtWarehouseComponent>dialog.content.instance;
						checnDevicesAtWarehouseForm.requestId = this.requestId;
						checnDevicesAtWarehouseForm.newActivityStatusId = this.selectedWorkflow.activityStatusToId;
						checnDevicesAtWarehouseForm.device = this.device;
						checnDevicesAtWarehouseForm.devices = this.devices;
						checnDevicesAtWarehouseForm.serviceCenterId = this.serviceCenterId;
						checnDevicesAtWarehouseForm.warehouseId = this.recipientWarehouseId;
						checnDevicesAtWarehouseForm.showWarehouseForm = false;
						checnDevicesAtWarehouseForm.isMobile = true;
						checnDevicesAtWarehouseForm.contragentId = this.customerContragentId;
						checnDevicesAtWarehouseForm.cancelEmitter.subscribe(() => {
							this.resetActivityOption();
						});
						checnDevicesAtWarehouseForm.continueEmitter.subscribe((devices) => {
							this.changeStatusForSerialNumber('', shortcutReturnEquipment);
						});
					});
					break;
				}
		}
	}

	get isReceiveTransfer(): boolean {
		return this.activity.statusId >= <number>ActivityStatusEnum.awaitingWarehouseAcceptance
	}

	showTransitionInstallAndUninstallWithWorkResult(transition: ActivityWorkflowTransition) {
		this.showCompleteWorkUninstallNew(transition, true);
	}

	showTransitionUninstallWithWorkResult(transition: ActivityWorkflowTransition) {
		this.showCompleteWorkUninstallNew(transition, false, true, false);
	}

	showTransitionInstallAndUninstallOptionalWithWorkResult(transition: ActivityWorkflowTransition) {
		this.showCompleteWorkUninstallNew(transition, true, false);
	}

	public showCompleteWorkUninstallNew(
		transition: ActivityWorkflowTransition, 
		withUsingDevices: boolean, 
		uninstallDevicesRequired: boolean = true,
		isServiceOrReplace: boolean = true) {
		const uninstallDialogRef = this.dialogService.open({
			title: "Смена статуса активности",
			content: CompleteWorkUninstallNew,
			width: this.isMobileDevice ? `90%` : '750px'
		});

		const completeWorkUninstallNewDialog = <CompleteWorkUninstallNew>uninstallDialogRef.content.instance;
		completeWorkUninstallNewDialog.requestId = this.requestId;
		completeWorkUninstallNewDialog.activityId = this.activity.activityId;
		completeWorkUninstallNewDialog.devices = this.toUninstallDevices;
		completeWorkUninstallNewDialog.nextActivityStatusId = ActivityStatusEnum.workEnded;
		completeWorkUninstallNewDialog.contragentId = this.customerContragentId;
		completeWorkUninstallNewDialog.showComment = true;
		completeWorkUninstallNewDialog.isServiceOrReplace = isServiceOrReplace;
		completeWorkUninstallNewDialog.withActivityResult = true;
		completeWorkUninstallNewDialog.withUsingDevices = withUsingDevices;
		completeWorkUninstallNewDialog.uninstallDevicesRequired = uninstallDevicesRequired;
		completeWorkUninstallNewDialog.activityStatusToId = transition.activityStatusToId;
		completeWorkUninstallNewDialog.activityWorkflowTransitionId = transition.activityWorkflowTransitionId;
		completeWorkUninstallNewDialog.continueEmitter.subscribe(() => {
			this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId));
		});
	}

	public showCompleteWorkInstall(transition: ActivityWorkflowTransition) {
		const installDialogRef = this.dialogService.open({
			title: "Смена статуса активности",
			content: WorkEndedSuccessInstall,
			width: this.isMobileDevice ? `90%` : '750px'
		});

		const wordEndedSuccessInstallDialog = <WorkEndedSuccessInstall>installDialogRef.content.instance;
		wordEndedSuccessInstallDialog.requestId = this.requestId;
		wordEndedSuccessInstallDialog.activityId = this.activity.activityId;
		wordEndedSuccessInstallDialog.nextActivityStatusId = ActivityStatusEnum.workEnded;
		wordEndedSuccessInstallDialog.activityStatusToId = transition.activityStatusToId;
		wordEndedSuccessInstallDialog.activityWorkflowTransitionId = transition.activityWorkflowTransitionId;
		wordEndedSuccessInstallDialog.onContinueEvent.subscribe(() => {
			this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId));
		});
	}

	private shortcutReturnEquipment(transition: ActivityWorkflowTransition) {
		this.requestsService.shortcutReturnEquipment(this.activity.activityId, transition.activityStatusToId)
			.subscribe((res) => {
				if (res.isSuccessful) {
					if (this.onChangeStatus) {
						this.onChangeStatus.emit(transition);
					}
				}
			});
	}


	hideTransitionButton() {
		if (this.selectedWorkflow && this.selectedWorkflow.activityWorkflowTransitionTypeId == ActivityWorkflowTransitionType.deviceToWarehouseAction)
			return true;
		return false;
	}

	private transitionSetPlannedDate(transition: ActivityWorkflowTransition) {
		const transitionConfirmationRequestRef = this.dialogService.open({
			title: `Переход в статус '${transition.transitionName}'`,
			height: this.isMobileDevice ? `100%` : '500px',
			width: this.isMobileDevice ? `100%` : '400px',
			content: SetPlannedDateComponent
		});

		const transitionConfirmationRequestDialog = <SetPlannedDateComponent>transitionConfirmationRequestRef.content.instance;
		transitionConfirmationRequestDialog.requestId = this.requestId;
		transitionConfirmationRequestDialog.cancelEmitter.subscribe(() => {
			this.resetActivityOption();
		});
		transitionConfirmationRequestDialog.onContinueEvent.subscribe(() => {
			this.changeStatusCore(transition, new ActivityChangeStatus(this.activity.activityId, transition.activityStatusToId))
		});
	}
}
