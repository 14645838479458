import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { RequestDevicesService } from "../../../services/request-devices.service";
import { RequestsService } from "../../../services/requests.service";
import { NewRequestDevice } from "../../../models/request/new-request/new-request-device";
import { GridComponent } from "@progress/kendo-angular-grid";
import { LookupService } from "../../../services/lookup.service";
import { forkJoin } from "rxjs";
import { KeyValueObject } from "../../../models/core/KeyValueObject";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { HierarchyLookupModel } from "../../../models/core/HierarchyLookupModel";
import { DeviceStatus, RequestType } from "../../../enums";
import { ReserveResult } from "../../../enums/reserve-result.enum";
import { DeviceModelDto } from "../../../models/device/device-model-dto";
import { DeviceModelsService } from "../../../services/device-models.service";
import { CustomerObjectsService } from "../../../../categories/customer-objects/customer-objects.service";
import { CustomerObjectDeviceDto } from "../../../models/customerObject/customer-object-device";
import { RequestBwt } from "../../../models/request/request-bwt";

@Component({
	selector: 'request-page-devices',
	templateUrl: './request-page-devices.component.html',
    styleUrls: ['./request-page-devices.component.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	],
	encapsulation: ViewEncapsulation.None,
})
export class RequestPageDevicesComponent implements OnInit {

	@ViewChild("mainRequestDevicesGrid") mainRequestDevicesGrid: GridComponent;

    @Input() requestId: number;
    @Input() requestTypeId: number;

    @Output() refreshRequestEvent: EventEmitter<RequestBwt> = new EventEmitter<RequestBwt>();

    loading: boolean = false;
    dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

    mainRequestDevices: NewRequestDevice[] = [];
    additionalRequestDevices: NewRequestDevice[] = [];
    customerObjectDevices: CustomerObjectDeviceDto[] = [];

    deviceTypes: KeyValueObject[] = [];
    deviceModels: DeviceModelDto[] = [];
    dropDownDeviceModels: DeviceModelDto[] = [];
    deviceSuppliers: DeviceModelDto[] = [];
    dropDownDeviceSuppliers: DeviceModelDto[] = [];
    marketPlaces: KeyValueObject[] = [];

    constructor (
        private lookupService: LookupService,
        private requestsService: RequestsService,
        public deviceModelsService: DeviceModelsService,
        private requestDevicesService: RequestDevicesService,
        private customerObjectsService: CustomerObjectsService
    ) { }

    ngOnInit(): void {
        this.loadRequestDevices();

        const deviceTypes$ = this.lookupService.getData('device-types', null);
		const deviceModels$ = this.deviceModelsService.getAll();
        const marketPlaces$ = this.lookupService.getData('device-market-places', null);

        forkJoin(deviceTypes$,  deviceModels$, marketPlaces$)
            .subscribe(([deviceTypes, deviceModels, marketPlaces]) => {
                this.deviceTypes = deviceTypes;
                this.deviceModels = deviceModels;
                this.dropDownDeviceModels = [];
                this.deviceSuppliers = this.deviceModels.filter(x => x.parentId == null);
                this.dropDownDeviceSuppliers = [];
                this.marketPlaces = marketPlaces;
            });
    }

    loadRequestDevices() {
        this.loading = true;
        const requestDevices$ = this.requestsService.getRequestDevices(this.requestId);
        const requestCustomerObjectDevices$ = this.customerObjectsService.getRequestCustomerObjectDevices(this.requestId);

        forkJoin(requestDevices$, requestCustomerObjectDevices$)
            .subscribe(([requestDevices, requestCustomerObjectDevices]) => {
                this.mainRequestDevices = requestDevices.filter(x => x.manuallyAdded == false);
                this.additionalRequestDevices = requestDevices.filter(x => x.manuallyAdded == true);
                this.customerObjectDevices = requestCustomerObjectDevices;
                this.loading = false;
            });
    }

    getNameById(items: KeyValueObject[] = [], id: number): string {
        return items.some(x => x.id == id)
            ? items.find(x => x.id == id).name
            : '';
    }

    createNewMainRequestDevice(): NewRequestDevice {
        let requestDevice = new NewRequestDevice;
        requestDevice.requestId = this.requestId;
        requestDevice.manuallyAdded = false;
        requestDevice.reserveResultId = <number>ReserveResult.notBooked;
        return requestDevice;
    }

    addNewMainRequestDevice() {
        this.mainRequestDevicesGrid.addRow(this.createNewMainRequestDevice());
    }

    saveMainRequestDeviceHandler({ dataItem }: any) {
        this.loading = true;
        let isNew = dataItem.requestDeviceId == null || dataItem.requestDeviceId == 0;

        if ((isNew && dataItem.customerDevice == true) || 
            (!isNew && dataItem.reserveResultId == <number>ReserveResult.notBooked)) 
        {
                dataItem.reserveResultId = <number>ReserveResult.equipmentReceivedByEngineer;
        }

        if (dataItem.requestDeviceId == null || dataItem.requestDeviceId == 0) {
            this.requestDevicesService.create(dataItem).subscribe(resp => {
                this.loadRequestDevices();
            });
        }
        else {
            this.requestDevicesService.update(dataItem, null).subscribe(resp => {
                this.loadRequestDevices();
            });
        }
    }

    removeMainRequestDeviceHandler({ dataItem }: any) {
        this.loading = true;
        this.requestDevicesService.remove(dataItem.requestDeviceId, null, null, false).subscribe(() => {
            this.loadRequestDevices();
            this.refreshRequest();
        });
    }

    public deviceTypeChange(deviceTypeId: number, requestDevice: NewRequestDevice = null) {
        if (requestDevice) {
            requestDevice.deviceModelId = null;
            requestDevice.deviceSupplierId = null;
        }
    }

    public deviceSupplierChange(supplierId: number, requestDevice: NewRequestDevice = null) {
        if (requestDevice) {
            requestDevice.deviceModelId = null;
        }
    }

    public editRequestDeviceHandler({ sender, rowIndex, dataItem }) {
        this.handleDeviceSuppliersDropdownOpen(dataItem.deviceTypeId);
        this.handleDeviceModelsDropdownOpen(dataItem.deviceSupplierId);

		sender.editRow(rowIndex);
	}

    public handleDeviceSuppliersDropdownOpen(deviceTypeId: number) {
        this.dropDownDeviceSuppliers = this.deviceModels != null && deviceTypeId
            ? this.deviceSuppliers.filter(x => x.typeId == deviceTypeId)
            : [];
    }

    public handleDeviceModelsDropdownOpen(deviceSupplierId: number) {
		this.dropDownDeviceModels = this.deviceModels != null && deviceSupplierId
            ? this.deviceModels.filter(x => x.parentId === deviceSupplierId)
            : [];
	}

    public checkCustomerObjectReclamationDevice(event: any, dataItem: CustomerObjectDeviceDto) {
        this.loading = true;
        if (event.target.checked) {
            this.requestDevicesService.createFromDevice(this.requestId, dataItem.deviceId).subscribe(() => {
                this.loadRequestDevices();
            });
        }
        else {
            this.requestDevicesService.deleteFromDevice(this.requestId, dataItem.deviceId).subscribe(() => {
                this.loadRequestDevices();
                this.refreshRequest();
            });
        }
    }

    refreshRequest() {
        this.requestsService.getBwtById(this.requestId).subscribe(resp => {
            this.refreshRequestEvent.emit(resp.entity);
        });
    }

    requestDeviceNotReserved(dataItem: NewRequestDevice): boolean {
        return dataItem == null || dataItem.deviceStatusId != DeviceStatus.reserved;
    }

    public canSaveRequestDevice(requestDevice: NewRequestDevice): boolean {
        return (requestDevice.deviceTypeId != null && 
            requestDevice.deviceSupplierId != null && 
            requestDevice.deviceModelId != null) ||
            requestDevice.customerDevice == true;
    }

    public rowClass() {
		return {
			'cursor-pointer': true
		};
	}

    get isReclamation(): boolean {
        return this.requestTypeId == <number>RequestType.reclamation;
    }
}