export enum AttachmentType {
	// Вложение к заявке
	requestAttachment = 1,

	// Вложение к комментарию
	commentAttachment = 2,

	// Акт выполненных работ
	workCompletedAttachment = 3,

	// Фото оборудования
	requestDeviceImage = 4,

	// Установаленная сим-карта (фото)
	installedSimFoto = 8,

	// С/Н демонтированного оборудования (фото)
	uninstalledDevicesFotos = 14,

	// Демонтированная сим-карта (фото)
	uninstalledSimFoto = 15,

	// С/Н установленного оборудования (фото)
	installedDevicesFotos = 16,

	// Акт передачи в ремонт
	transferAcceptanceCertificate = 17,

	// Акт передачи в ремонт
	transferCertificateForRepair = 18,

	// Товарная накладная
	packingList = 19,
	
	// Селфи с парпортом
	selfieWithPassport = 21,

	// Товарная накладная
	productInvoice = 23
}
